@import url(https://fonts.googleapis.com/css2?family=Teko:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Teko:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Teko:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Teko:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Teko:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Teko:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@300&display=swap);
*{

    margin: 0;
    padding: 0;
}
body {
  margin: 0;
  
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


#Navbar_Mustang{


    background-color: #0d0d0d;
    border-color: white;
    
}

#Icon_Navbar{

    color: white;
    font-size: 2.8em;
    
    
}
#Icon_Navbar_Close{

    color: #f8f2f3;
    font-size:3.6em;
    position: absolute;
    right: 0;
    padding: 4px;
    margin: 20px;
    
}

.Logo_Mustang{
    
    width: 120px;
    margin: 0 auto;
    margin-top: 0.3em;
    margin-bottom: 0.3em;
    
}

#Btn_Panier_Accueil{

    font-size: 1.5em;
    background-color: #464646;
    color: #FFFFFF;
    Width: 6em;
    height: 2em;
    justify-content: center;   
    text-align: center;
    margin: 0 auto;
    box-shadow: none;
    font-family: Teko;
    border: none;
    border-radius: 15px;
}

#Btn_Ouvert_Accueil{

    font-size: 1.5em;
    background-color: #464646;
    color: #FFFFFF;
    Width: 6em;
    height: 2em;
    box-shadow: none;
    font-family: Teko;
    border-color: #000000;
    border-radius: 15px;
    
}




#icon_ouvert{

    fill: #0FE617;
    font-size: 1em;
    text-align: left;
    margin-right: 7%;
}

#icon_ferme{

    fill: #e60f0f;
    font-size: 1em;
    text-align: left;
    margin-right: 7%;
}

.Navbar_Text{
    color: #FFFFFF;
    text-align: left;
    padding: 10px 40px;
}

.Navbar_Text .MuiTypography-body1{

    font-family: Teko;
    font-size: 1.8rem;
    font-weight: bold;
    margin: 0 auto;
}

#test .MuiPaper-root{

    background-color: #464646;
    width: 27em;
    
}

#test .MuiList-padding {
    width: -webkit-max-content;
    width: max-content;
    padding-top: 6em;
    margin-left: 2em;
}




@media only screen and (max-width: 960px) {
    

    #Icon_Navbar{

        color: white;
        font-size: 2em;
        
        
    }

    #Btn_Panier_Accueil{

        font-size: 1.3em;
        Width: 5.5em;
        height: 2em;
        display: none;
        
    }

    #Btn_Ouvert_Accueil{

        font-size: 1.2em;
        
        height: 2em;
        
    }

    .Logo_Mustang{

        width: 5em;

        
        
    }
    #text_ouvert{

        font-size: 0.8em;
    }

}

@media screen and (max-width: 500px) {
    #test .MuiPaper-root{

        width: 100%;
        
    }
}

.menu-sidee {
    margin-left: 29px;

}
.panier {
  max-width: 80% !important;
  margin: 80px auto 2rem !important;
  padding: 1rem !important;
}

.panier__vide {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-top: 4rem;
}
.panier__vide img {
  max-width: 80%;
  object-fit: contain;
}

.panier__vide{
  text-align: center;
  margin: 2rem 0;
  font-weight: 450;
  font-size: 2rem;
}

.panier__container {
  padding: 4rem 0rem;
}

.panier__nombre-articles {
  text-align: center;
  font-weight: bold;
  margin-bottom: 2rem;
}

.panier__container--prix {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1.5rem 0;
}
.panier__container--prix h1 {
  font-size: 1.5rem;
  font-weight: 600;
  color: black;
}
.panier__container--prix p {
  font-size: 2rem;
  font-weight: 800;
  margin-bottom: 0rem;
}

.panier__container--btn-valider {
  text-align: center !important;
  background: rgb(77, 76, 76) !important;
  margin: auto !important;
  display: block !important;
}

.panier__choice-delivery {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
}

.choice-delivery__form {
  margin: 1rem;
}

.go-back {
  font-size: 1.5rem;
  font-weight: 800;
  padding-left: 15px;
  position: relative;
  border: none;
  background-color: #464646;
  color: white;
  padding: 10px 46px 10px 28px;
  border-radius: 15px;
  text-transform: uppercase;
}
.go-back:hover {
  background-color: #252525;
}

.go-back .MuiSvgIcon-root {
  font-size: 32px;
  margin-right: 20px;
}




@media (max-width: 850px) {
  .panier__container {
    padding: 1rem 3rem;
  }
}

@media (max-width: 450px) {
  .panier__container {
    padding: 1rem 1rem;
  }
}

@media (max-width: 600px) {
  .panier__vide img {
    max-width: 70%;
  }
  .panier__container_titre {
    font-size: 40px !important;
    margin: 0;
    line-height: 60px;
  }
  .go-back {
    font-size: 0.9rem !important;
    padding: 7px 20px 5px 20px;

  }
  .go-back .MuiSvgIcon-root {
    font-size: 27px;
    margin-right: 10px;
  }
  .panier__nombre-articles {
    text-align: center;
    font-weight: bold;
    margin-bottom: 0;
  }

  .panier__vide h1 {
    font-size: 1.3rem;
  }

  .panier__container--prix h1 {
    font-size: 1.3rem;
  }
  .panier__container--prix p {
    font-size: 1.3rem;
  }
}

@media (min-width: 350px) and (max-width: 380px) {
  .panier__container {
    padding: 4rem 10px;
  }
}

.panier__container_titre{

  font-family: Teko;
  font-style: normal;
  font-weight: bold;
  font-size: 62px;
  line-height: 80px;
  text-align: center;
  color: #292929;
}


.product {
  border-bottom: 1px solid #ccc;
  width: 100%;
  display: block ruby;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  padding: 2rem 0;
}

.product-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 3em;
  min-width: 400px;
}

.product img {
  width: 100%;
  margin: 30px auto;
}

.product--info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 1rem;
}

.product--info p {
  margin: 0;
}

.product--info-title {
  font-size: 1.5rem;
  margin-bottom: 0rem !important;
  justify-self: center;
}

.product-list p {
  margin-bottom: 0;
}

.menu__details-title {
  font-weight: bold;
  margin: 0 0 0 2rem;
}

.menu__details {
  margin: 0 0 0 3rem;
  width: 100%;
}

.product--info-prix {
  font-size: 1.5rem;
  justify-self: center;
  align-self: center;
  margin-bottom: 0px !important;
  font-weight: thin;
}

.product--quantite {
  justify-self: center;
  align-self: center;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-weight: bold;
  -moz-box-shadow-bottom: 2px 2px 2px grey;
  -webkit-box-shadow-bottom: 2px 2px 2px grey;
  box-shadow: 2px 2px 2px grey;
  border-radius: 18px;

}
.product--quantite button {
  margin: 0 10px;
}



.delete__product .MuiSvgIcon-root {
  color: rgb(22, 20, 20);
  font-size: 1.5rem;
  margin-top: -1px;
  margin-left: 20px;
}

.ecarter-prix {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 600px) {
  .product--info-title {
    font-size: 1.2rem;
  }
  .product-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 0.5em;
    min-width: 100px;
  }
}

@media (max-width: 350px) {
  .product {
    grid-template-columns: 1fr;
    grid-gap: 1rem;
  }

}

/* ------- Product of panier ----------*/
.product-img {
  background-color: #EFF0F1;
  height: -webkit-max-content;
  height: max-content;
  width: -webkit-max-content;
  width: max-content;
  border-radius: 30px;
}


.btn-remove .MuiSvgIcon-root {
  background-color: #464646;
  color: white;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 4px;
  font-size: 1.5rem;
}

.btn-add .MuiSvgIcon-root{
  background-color: #464646;
  color: white;
  margin-left: 10px;
  border-radius: 4px;
  font-size: 1.5rem;
}

.text-quantite {
  font-weight: 700;
}

.product__prix {
  text-align: end;
}

.product .MuiFormHelperText-root.MuiFormHelperText-root {
  color: #464646;
}
.panier__fieldset {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.radio__panier-choice {
  margin: 0 0 1rem 0 !important;
  color: white;
  background-color: #464646;
  padding: 10px 30px;
  border-radius: 15px;


}

.radio__panier-choice:hover {
  background-color: #1f1e1e;
}

.radio__panier-choice .MuiTypography-body1 {
  font-size: 25px;
  text-transform: uppercase;
  font-weight: 800;
}

.radio__panier-choice span {
  margin: 0 0.2rem;
  color: #FFFFFF;
}

.radio__panier-zone {
  margin-bottom: 40px;
}

.radio__panier-zone span {
  margin: 0 1.2rem;
}

.radio__text-zone {
  margin-bottom: -20px;
}

.livraison__container {
  text-align: center;
}

.livraison__container p {
  margin-top: 20px;
  font-style: italic;
  margin-bottom: 10%;
}

.panier__container--btn-valider {
  margin-top: 2rem !important;

}

.error_cart {
  text-align: center;
}

.CGV__container {
  display: flex;
  margin-top: 3rem;
}

.CGV__container p {
  margin-bottom: 0rem;
  margin-left: 3px;
  width: auto;
  text-align: center;
}

.CGV__container p a {
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
  color: black;
}

.CGV__container p a:hover {
  color: black;
}

.MuiCheckbox-colorPrimary.Mui-checked {
  color: #464646 !important;
}

.MuiRadio-colorSecondary.Mui-checked {
  color: #1b1b1b !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #1b1b1b !important;
}

@media (max-width: 535px) {
  .CGV__container p {
    width: 80%;
  }
}



#btn_commander_panier {

  font-size: 1.6em;
  font-weight: bold;
  background-color: #464646 !important;
  color: #FFFFFF;
  Width: 7em;
  height: 2em;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
  box-shadow: none;
  font-family: Teko;
  /* border-color: #464646; */
  border-radius: 15px;
}

#btn_commander_panier:hover {
  background-color: #FFFFFF;
}


.livraison__container h1 {
  font-family: Teko;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  margin-top: 1em;
  text-align: center;
  color: #292929;
}

.div_emporter {


  width: 90%;
  height: -webkit-max-content;
  height: max-content;
  background: #1C1F2B;
  border-radius: 25px;
  margin: 0 auto;
  margin-top: 2em;
  padding-bottom: 30px;
}

.text_mode_livraison {

  color: #FFFFFF;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 40px;
  font-family: Teko;
  font-style: normal;
  font-weight: bold;
  font-size: 64px;


}

@media screen and (max-width:1050px) {

  .text_mode_livraison {

    color: #FFFFFF;
    text-align: center;
    margin-top: 1em;
    margin-bottom: 1em;
    font-family: Teko;
    font-style: normal;
    font-weight: bold;
    font-size: 7vw;

  }

  .radio__panier-choice .MuiTypography-body1 {
    font-size: 15px;
  }

  .div_emporter {
    width: 90%;
    height: -webkit-max-content;
    height: max-content;
  }

  .panier {
    max-width: 100% !important;
  }
}

#radio_emporter {

  padding-left: 1em;
}

/*=========== PROMO CODE SECTION ======================*/

#promo {
  width: 100%;
  display: flex;

}

.stylePromo {
  margin-right: 5%;
}

.promo--btn-text img {
  max-width: 30px;
  object-fit: contain;
}

.promo--btn {
  font-family: "Teko";
  width: 20%;
  background: #464646 !important;
}

.promo--btn:hover {
  color: #131313;
}

.promo--btn-text h4 {
  margin-top: 2%;
}
@media  screen and (max-width: 400px) , (max-height: 800px) {
  #promo {
    flex-direction: column;
  }
  .promo--btn {  
    width: 50%;
  }
}
@media  screen and (max-width: 800px) {
  #promo {
    /* flex-direction: column; */
  }
  .promo--btn {  
    width: 30%;
  }
}
.procedure-panier {
    border-bottom: solid 0.2px lightgray;
    display: flex;
    padding-bottom: 30px;
    margin-bottom: 30px;
    width: -webkit-max-content;
    width: max-content;
    margin: 0 auto;
  }
  
  .procedure-in {
    background-color: #464646;
    font-size: 3em;
    color: white;
    font-family: Teko, sans-serif;
    padding: 20px 50px;
    font-weight: 700;
    border-radius: 100%;
  }
  
  .procedure-out {
    background-color: #1C1F2B;
    font-size: 3em;
    color: white;
    font-family: Teko, sans-serif;
    padding: 20px 50px;
    font-weight: 700;
    border-radius: 100%;
  }

  .procedure-icon {
    width: 20vw;
    margin-top: 58px;
    border-top: solid 0.2px lightgray;
  }

  .procedure-text {
    font-size: 18px;
    width: 100px;
    margin: 0 auto;
    text-align: center;
  }

  @media screen and (max-width: 959px) {
    .procedure-icon {
        width: 10vw;
        margin-top: 34px;
      }
      .procedure-in, .procedure-out {
        font-size: 2em;
        padding: 7px 27px;
      }
      .procedure-text {
        font-size: 11px;
        width: 60px;
        margin: 0 auto;
      }
  }
.paiment {
  /* min-height: 92vh;
  display: flex;
  align-items: center; */
}

.paiement__container {
  margin-top: 100px !important;
  max-width: 600px;
  margin: 1rem auto;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 20px 2px #e6e3e3;
  position: relative;
}

.paiement__close {
  position: absolute;
  top: 2px;
  right: 10px;
  cursor: pointer;
}
.paiement__close i,
.paiement__back i {
  font-size: 20px;
  pointer-events: none;
}

.paiement__back {
  display: none;
  position: absolute;
  top: 2px;
  left: 10px;
  cursor: pointer;
}

.paiement__back.active {
  display: block;
}

.paiement__container h1 {
  
font-family: Teko;
font-style: normal;
font-weight: bold;
font-size: 42px;
line-height: 103px;
text-align: center;
color: #292929;
}

.paiement__process {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}
.paiement__process p {
  margin: 0;
}

.paiement__process--options,
.paiement__process--options {
  display: flex;
  align-items: center;
  padding: 0 10px;
}

.paiement__process--options span {
  margin-right: 10px;
  background-color: #ccc;
  padding: 2px 10px;
  border-radius: 50%;
  color: #fff;
}

.paiement__process hr {
  flex: 1 1;
  height: 1px;
  border: 0;
  background-color: lightgray;
  margin-bottom: 10px;
}

.paiement__process--options.active span {
  background-color: #464646;
}

.paiement__details__recapitualitfs,
.paiement__details__modePaiement {
  padding: 10px 0;
}

.paiement__details__recapitualitfs h2,
.paiement__details__modePaiement h2 {
  font-size: 1.2rem;
}

.paiement__details__recapitualitfs p {
  margin: 0;
}

.paiement__details__product__details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
}

.paiement__details__product__details-info-quantite {
  font-size: 15px;
  color: rgb(126, 125, 125);
}

.paiement__details__recapitualitfs-total {
  display: flex;
  justify-content: space-between;
  margin: 10px 0 0;
  padding: 0 0 10px;
  border-bottom: 1px solid lightgray;
}

.paiement__details__recapitualitfs-total p {
  font-weight: bold;
}

.paiement__details__recapitualitfs-total-prix {
  font-weight: 600;
}

.paiement--btn {
  text-align: center !important;
  background: rgb(77, 76, 76) !important;
  margin: auto !important;
  display: flex !important;
}

.paiement__form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  /* padding: 1rem; */
}

.paiement__form #card-element {
  border-radius: 4px 4px 0 0;
  padding: 12px;
  border: 1px solid rgba(50, 50, 93, 0.1);
  max-height: 44px;
  width: 100%;
  background: white;
  box-sizing: border-box;
  margin-top: 10px;
}

.paiement__form .StripeElement--webkit-autofill {
  background: transparent !important;
}

.paiement__form .StripeElement .CardField-number input:-webkit-autofill,
.paiement__form .StripeElement .CardField-number input {
  -webkit-text-fill-color: #fce883 !important;
  -webkit-transition: none !important;
  transition: none !important;
}

.paiement__details__error {
  text-align: center;
  font-size: 14px;
  color: rgb(243, 83, 83);
  margin: 10px 0;
}

.paiement--btn-payer-text img {
  max-width: 30px;
  object-fit: contain;
}

.paiement__details__securise {
  margin: 10px auto 0;
  text-align: center;
  color: rgb(107, 107, 107);
  font-size: 13px;
}

.delivery-infos__container {
  padding: 6px 0 7px;
  border-bottom: 2px solid #464646;
  margin: 1rem 0 2.5rem !important;
}

@media (max-width: 600px) {
  .paiement__process--options span {
    background-color: #ccc;
    padding: 1px 5px;
    border-radius: 50%;
    color: #fff;
    font-size: 12px;
  }

  .paiement__process p {
    font-size: 12px;
  }
  .paiement__details__recapitualitfs,
  .paiement__details__modePaiement {
    padding: 16px 0;
  }
}

#btn_suivant_paiement{

  font-size: 1.6em;
    font-weight: bold;
    background-color: #464646 !important;
    color: #FFFFFF;
    Width: 7em;
    height: 2em;
    justify-content: center;   
    text-align: center;
    margin: 0 auto;
    box-shadow: none;
    font-family: Teko;
    border-color: #464646;
    border-radius: 15px;
}

#btn_payer_paiement{

    font-size: 1.6em;
    font-weight: bold;
    background-color: #464646 !important;
    color: #FFFFFF;
    Width: 100%;
    height: 2em;
    justify-content: center;   
    text-align: center;
    margin: 0 auto;
    box-shadow: none;
    font-family: Teko;
    border-color: #464646;
    border-radius: 10px;
}
.myModal__backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.myModal__modal {
  max-height: 100vh;
  overflow-y: auto;
  max-width: 500px;
  width: 95%;
  padding: 1.5rem;
  background: white;
  border-radius: 10px;
  position: relative;
  font-family: "Teko";
}

.myModal__modal__close {
  position: absolute;
  top: 2px;
  right: 10px;
}
.myModal__modal__close i {
  font-size: 20px;
  pointer-events: none;
}

.myModal__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  margin-bottom: 10px;
  border-bottom: 1px solid lightgray;
}

.myModal__header h1 {
  text-align: center;
  color: rgb(100, 100, 100);
  font-size: 2.2rem;
}
.myModal__header h2 {
  text-align: center;
  color: rgb(100, 100, 100);
  font-size: 1.5rem;
}
.myModal__header p {
  margin: 0;
}

.myModal__header img {
  max-width: 200px;
  max-height: 150px;
  object-fit: contain;
}

.myModal__body,
.myModal__footer {
  padding: 10px 0;
  border-bottom: 1.2px solid lightgray;
}

.myModal__body__heading {
  font-size: 1.3rem;
}

.myModal__footer {
  border: none;
  display: flex;
  justify-content: center;
}


@media (max-width: 600px) {
  .MuiButton-label {
    padding: 1rem;
    font-size: 1rem;
  }
  .MuiButton-label .MuiButton-endIcon{
    display: none !important;
  }
}

@media (max-width: 500px) {
  .myModal__header img {
    max-width: 175px;
    max-height: 125px;
  }

  .myModal__header h1 {
    font-size: 1.8rem;
  }
  .myModal__header h2 {
    font-size: 1.3rem;
  }
  .myModal__modal {
    margin-top: 5%;
  }
}

.felicitation {
  height: 91vh;
  display: grid;
  place-content: center;
  place-items: center;
  text-align: center;
}

.felicitation__container {
  position: relative;
  padding: 2rem 10px;
  border-radius: 10px;
  box-shadow: 1px 0px 17px 4px #e6e6e6;
  max-width: 800px;
  width: 100%;
  margin: 10px;
}

.felicitation__svg {
  position: absolute;
  top: -40px;
  left: 50%;
  width: 200px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  pointer-events: none;
}

.felicitation__container h1 {
  color: #464646;
}

.felicitation__container h2 {
  color: rgb(97, 97, 97);
}
.felicitation__container h1 {
  font-weight: 600;
  letter-spacing: 1px;
  margin-bottom: 10px;
}
.felicitation__container h2 {
  font-size: 1.7rem;
  margin-bottom: 10px;
}
.felicitation__container p {
  color: #464646;
}

.carte {
  box-sizing: border-box;
  padding: 1em 1em 2em 1em;
  margin-left: 2.5rem;
  height: -webkit-max-content;
  height: max-content;
  margin-right: 2.5rem;
  background-position: center;
}

.carte_contain {
  background-color: #464646;
  border-radius: 20px !important;
  margin-left: 2em;
  margin-top: 2em;
}

.carteTitle {
  color: white;
  font-family: "Teko" !important;
  text-align: center;
  font-size: 44px !important;
  font-weight: 700 !important;
  line-height: 72px;
  letter-spacing: 15% !important;
}
.carteStar {
  width: 30px !important;
}

.carte__image-container {
  display: flex;
  justify-content: center;
  padding: 0.1em;
}

.carte__image-container img {
  width: 102px;
  height: 93px;
  object-fit: contain;
}

.carte_title {
  font-style: normal;
  font-size: 2.5rem;
  line-height: 90px;
  text-align: center;
  letter-spacing: 0.02em;
}

.carte__items {
  margin-top: 15px;
  padding: 0 25px 1em;
  text-align: center;
  margin-bottom: -1em;
}

.carte__item {
  color: white;
  padding: 5px 0;
  cursor: pointer;
  position: relative;
  font-size: 1.5rem;
  border-radius: 5px;
  text-align: left;
  font-family: "Teko" !important;
  width: 240px;
}

.carte__item:hover {
}

.carte__item::before {
  position: absolute;
  left: -50px;
  width: 40px;
  height: 40px;
  content: "";
  background-size: 80%;
  background-position: center;
  background-repeat: no-repeat;
  top: -1px;
  display: none;
}

.carte__item.active::before {
  display: block;
}
.carte__item.active {
  font-size: 1.5rem;
  font-weight: 700;
}


.carte.active {
  height: 100%;
}

@media (max-width: 1080px) {
  .carte {
    position: absolute;
    top: 0;
    left: -120%;
    padding-top: 2rem;
    /* left: -500px; */
    width: 100%;
    z-index: 99;
    transition: all 0.5s;
  }

  .carte.active {
    left: 0px;
    overflow: visible;
    margin-left: 0px !important;
  }

  .carte__item {
    color: rgb(238, 238, 238);
  }

  .commander__carte i {
    position: absolute;
    left: 2rem;
    margin-bottom: -4em;
    color: rgb(238, 238, 238);
  }
}

@media (max-width: 800px) {
  .carte__image-container img {
    max-width: 50%;
  }
  .carte_contain {
    margin-right: 2em;
  }
}

@media (max-width: 600px) {
  .carte_title {
    line-height: normal;
  }
  
}

@media (max-height: 700px) {
  .carte_title {
    display: none;
  }
  .carte__item {
    margin-bottom: 0px;
  }
}
@media (max-width: 500px) {
  .commander__carte i {
    position: absolute;
    left: 14px;
    margin-bottom: -2em;
  }
  .carte_contain {
    background-color: #1C1F2B;
    border-radius: 20px !important;
  }
  .carte__item.active {
    font-size: 2.2rem;
    font-weight: 700;
  }
  .carte__item{
    font-size: 2.2rem;
  }
  
}

@media (min-width: 1080px) {
.commander__container__cards {
    display: flex;
    align-items: flex-start;
    align-content: start;
    width: 100%;
}
}

/* for carte not backdrop */
.modal-backdrop{
    position: inherit !important;
}

/* style for carte side */
.menu-side {
  padding-top: 5em;
}

/* Arrow icon for Menu Side */
.menu-arrow-back .MuiSvgIcon-root {
  fill: white;
  font-size: 50px;
  margin: 0.3em;
}

.menu-arrow-forward .MuiSvgIcon-root {
  fill: white;
  font-size: 50px;
  position: fixed;
  margin-top: -19px;
}

.menu-offcanvas .offcanvas-body {
  background-color: #1C1F2B;
  padding: 0rem 0rem;
  
}

.menu__order {
    width: 100%;
    margin-top: 1rem;
}

.menu__header {
    width: 60%;
    margin: auto;
    text-align: center;
    margin-bottom: 2.5rem;
}

.menu__header h2 {
    font-size: 3rem;
    font-weight: bold;
}

.menu__prix {
    font-size: 3rem;
    font-weight: bold;
}

.menu__instuctions {
    font-size: 1.5rem;
    font-style: italic;
}

.menu__price-info {
    font-weight: bold;
    font-size: 1.5rem;
}

.menu__header-img{
    height: 150px;
    width: auto;  
}

.menu__category-container h3 {
    font-size: 3rem;
    margin: 1rem 0;
}

.cart__menu-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 2.5rem;
}

.button__menu-cart{
    justify-self: center;
    align-self: center;
    display: flex;
    justify-content: flex-end;
    align-items: space-between;
    font-weight: bold;
    -moz-box-shadow-bottom: 2px 2PX 2px grey;
    -webkit-box-shadow-bottom: 2px 2px 2px grey;
    box-shadow: 2px 2px 2px grey;
    border-radius: 18px;
    padding: 0.6rem 0.3rem;
    font-size: 1.5em;
    width: 100px;
    align-self: flex-end;
    margin-right: 5rem !important;
}

@media (max-width: 800px) {
    .menu__header-img {
      width: 250px;
      height: auto;
    }
}

@media (max-width: 450px) {
    .menu__header-img {
        width: 200px;
        height: auto;
    }

    .menu__header h2 {
        font-size: 2.5rem;
        margin-top: 4rem;
    }

    .menu__instuctions {
        font-size: 1.5rem;
    }

    .menu__category-container h3 {
        font-size: 2rem;
    }
    .menu__category-container {
        height: 100%;
    }
    
}


.cardMenu__item {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    background: rgb(241, 241, 241);
    border-radius: 10px;
    justify-content: space-between;
    height: 350px; 
    width: 350px;
    margin: 1rem;
    text-align: center;
  }
  
  .item_unavailable {
    opacity: 0.5;
  }
  
  .cardMenu__item__heading {
    text-align: center;
    color: rgb(100, 100, 100);
    font-size: 2rem;
  }
  
  .cardMenu__item__image-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .cardMenu__item__image-container img {
    /* taille image */
    max-width: 200px;
    max-height: 150px;
    object-fit: contain;
  }
  
  .cardMenu__item__details {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 1px solid black;
    margin: auto;
    cursor: pointer;
    
  }
  
  .cardMenu__item__details p {
    font-size: 1.5rem;
    font-weight: bold;
    margin-top: 1rem;
   
  }
  
  
  .cardMenu__item__shopping-btn {
    position: absolute;
    bottom: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
  
  .cardMenu__item__commander-btn {
    text-align: center !important;
    /* margin: auto !important; */
    display: flex !important;
  }
  
  .myModal__header h1 {
    color: black !important;
    
  }
  
  .myModal__footer {
    justify-content: space-between !important;
  }

  .myModal__footer button{
    margin: auto;
  }

  .myModal__footer div{
    margin: auto !important;
  }
  
  .myModal__footer p {
   
  }
  
  .myModal__header p {
 
    
  }
  
  .myModal__footer div:first-child {
    justify-self: center;
    align-self: center;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-weight: bold;
    -moz-box-shadow-bottom: 2px 2PX 2px grey;
    -webkit-box-shadow-bottom: 2px 2px 2px grey;
    box-shadow: 2px 2px 2px grey;
    border-radius: 18px;
    padding: 0.4rem;
    margin: 0 10px;
    
  } 
  
  .MuiButtonBase-root {
    padding: 0px !important;
  }
  
  .MuiButton-label {
    background: #000000 !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    padding: 1rem !important;
    color: white !important;
    font-size: 1.3rem;
    font-weight: 900;
  }
  
  @media (max-width: 1100px) {
    .cardMenu__item__heading {
      font-size: 1.7rem;
    }
  }
  
  @media (max-width: 545px) {
    .cardMenu__item__image-container img {
      /* taille image */
      max-width: 175px;
      max-height: 125px;
      object-fit: contain;
    }
    .cardMenu__item__heading {
      font-size: 1.4rem;
    }
  }

  @media (max-width: 400px) {
    .cardMenu__item {
      width: 300px;
      margin: 1rem 0;
    }
  }

  @media (max-width: 400px) {
    .cardMenu__item {
      width: 300px;
      margin: 1rem 0;
    }
  }

  @media (max-width: 330px) {
    .cardMenu__item {
      width: 250px;
    }
  }
  
.sides__radio-container {
  display: flex;
  flex-direction: column;
}

.sides-radioGroup {
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
  align-items: self-end;
}

.sides-radioGroup h2 {
  margin-bottom: 0;
  margin-left: 1rem;
}


.menu__go-back {
    font-size: 1.5rem;
    font-weight: 600;
    /**
     * If you set it to a value higher than the 'font-size', 
     * you will have to take account of the difference 
     * between the 'line-height' and the 'font-size' 
     * to vertically centre the arrow and align it with the text.
     **/
    line-height: 1;
  
    /* Makes some place for the arrow */
    padding-left: 15px;
  
    /* The arrow will be absolutely positioned */
    position: relative;
    border: none;
    background-color: white;
    position: absolute;
  }
  
  .menu__go-back::before {  
    color:  white;
    border-style: solid;
    border-width: 0 0 2px 2px;
    content: "";
    display: block;
    height: 8px;
    left: 0px;
    position: absolute;
    width: 8px;
  
    /* These two lines vertically centre the point of the arrow */
    margin-top: -3px;
    top: 50%;
  
    /* The arrow sprints to the left when the link is hovered */
    transition: left 0.1s;
  
    transform: rotate( 45deg );
    -webkit-transform: rotate( 45deg );
  }
  
  .dishes__displayed-menu {
    position: relative;
  }

  .menu__type-items{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    grid-gap: 10px;
    grid-gap: 10px;
    gap: 10px;
    width: 100%;
    justify-content: center;
  }

  .menu__type-item {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    background: rgb(241, 241, 241);
    border-radius: 10px;
    justify-content: space-between;
    /* cursor: pointer; */
    height: 300px;
    cursor: pointer;
    width: 500px;
    margin: 1rem;
    align-items: center !important;
    align-self: center;
  }

  .menu__type-item img {
    max-width: 200px;
    max-height: 150px;
    object-fit: contain;
  }

  .menu__type-item p {
    font-size: 1.5rem;
    font-weight: bold;
    margin-top: 1rem;
    color: red;
  }

  .menu__type-item h3 {
    text-align: center;
    color: black;
    font-size: 1.8rem;
  }

  .menu__ordering-time{
    font-size: 1.5rem;
    margin-bottom: 2rem;
    font-weight: bold;
    text-align:center;
    font-style: italic;
  }


  
  @media (max-width: 420px) {
    .menu__type-item {
      width: 300px;
    }
  }

  @media (max-width: 320px) {
    .menu__type-item {
      width: 210px;
      height: 350px;
    }
  }
  
        
 
.card__item {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-radius: 10px;
  justify-content: space-between;
  height: 320px;
  cursor: pointer;
  width: 320px;
  margin: 1rem;
  background-position: center;
}

.card_contain {
  background-color: white;
  border-radius: 10px;
  height: 290px;
}

.item_unavailable {
  opacity: 0.5;
}

.MuiFormHelperText-root {
  font-weight: bold;
  color: red;
}

.card__item__heading {
  text-align: center;
  color: black;
  font-size: 30px;
  padding-top: 2%;
  font-family: "Teko";
}

.separation_ligne {
  padding: 10px 0;
  border-bottom: 1.2px solid lightgray;
}
.card__item__image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card__item__image-container img {
  /* taille image */
  width: 230px;
  height: 160px;
  object-fit: contain;
  align-items: center;
  justify-content: center;
}

.card__item__details {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.card__item__details p {
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 2rem;
  color: red !important;
}

.card__item__details i {
  cursor: pointer;
}

.card__item__shopping-btn {
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.card__item__commander-btn {
  text-align: center !important;
  /* margin: auto !important; */
  display: flex !important;
  font-family: "Roboto";
}

.myModal__header h1 {
  color: black !important;
}

.myModal__footer {
  justify-content: space-between !important;
  height: 100%;
}

.myModal__footer p {
  font-weight: bold;
}

.myModal__header p {
  font-weight: bold;
}

.myModal__footer div:first-child {
  justify-self: center;
  align-self: center;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-weight: bold;
  -moz-box-shadow-bottom: 2px 2px 2px grey;
  -webkit-box-shadow-bottom: 2px 2px 2px grey;
  box-shadow: 2px 2px 2px grey;
  border-radius: 18px;
  padding: 0.4rem;
  margin: 0 10px;
}

.MuiButtonBase-root {
  padding: 0px !important;
}

.MuiButton-label {
  background: #000000 !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  padding: 1.5rem;
  color: white !important;
  font-family: "Roboto";
}
.MuiFormHelperText-root.MuiFormHelperText-root {
  color: red;
  margin: 0;
  font-size: 0.75rem;
  margin-top: 3px;
  text-align: left;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: bold;
  line-height: 1.66;
  letter-spacing: 0.03333em;
}

@media (max-width: 1100px) {
  .card__item__heading {
    font-size: 1.7rem;
  }
}

@media (max-width: 545px) {
  .card__item__image-container img {
    /* taille image */
    max-width: 175px;
    max-height: 125px;
    object-fit: contain;
  }
  .card__item__heading {
    font-size: 1.4rem;
  }
}

/* ce span est pour l'affichage des prix des supplements avec couleurs grey  */
.span_card{

  font-style: none;
  /* font-style: italic; */

  font-size: 1rem;
}

@media (max-width: 400px) {
  .card__item {
    width: 250px;
    color: red !important;
  }
  body {
    height: 100%;
  }
}


@media (max-width: 330px) {
  .card__item {
    width: 250px;
  }
  
}


.commander {
    margin-top: 46px !important;
    background-image: url(/static/media/cdn_fond_menu.8f70633e.png);
    background-repeat: repeat;
    background-size: contain;
    background-color: #0d0d0d;

  }
  
  .commander__container {
    padding: 2rem 1rem;
    position: relative;
    display: flex;
    flex-direction: row;
    height: 100%;
  }
  
  .commander__container__cards {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row !important;
    justify-content: center;
  }
  
  .commander__container__cards fieldset {
    width: 100%;
  }
  
  .dishes-menu__radio-container {
    display: flex;
    flex-direction: row !important;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .menu__radio-container {
    flex-direction: row !important;
  }
  
  .commander__carte {
    display: none;
  }
  
  .radio-choice__menu {
    display: flex;
    justify-content: center;
    margin: 0 !important;
  }
  
  .lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
  }
  
  .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid black;
    border-color: black transparent black transparent;
    -webkit-animation: lds-dual-ring 1.2s linear infinite;
            animation: lds-dual-ring 1.2s linear infinite;
  }
  @-webkit-keyframes lds-dual-ring {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  @keyframes lds-dual-ring {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  
  @media (max-width: 1080px) {
    .commander__container {
      grid-template-columns: 1fr;
      padding: 3rem 1rem !important;
    }
  
    .commander__carte {
      display: block;
      position: absolute;
      top: 35px;
      left: 10px;
      z-index: 100;
    }
  
    .commander__carte.white i {
      color: rgb(238, 236, 236) !important;
    }
  }
  

.formulaireTitle{

      padding-top: 1em !important;
      color: white;
      font-family: "Teko" !important;
      text-align: center;
      font-size: 75px !important;
      font-weight: 900 !important;
      padding-top: 30px;
      line-height: 132px;
      text-transform: uppercase;
      letter-spacing: 5% !important;
}

.contact-coord{
  background-color: white;
  margin: 0 auto;
  width: 80%;
  padding-bottom: 4em;
  border-radius: 20px;
}

.contact-coord h2{
  font-family: "Teko";
  font-size: 48px !important;
  font-weight: 600 !important;
  padding-top: 50px;
  padding-bottom: 40px;
  line-height: 69px;
  text-transform: uppercase;
  letter-spacing: 5% !important;
  text-align: center;
  
}

#Btn_Envoyer_Franchise{

  font-size: 1.5em;
  background-color: #C3232B;
  color: #FFFFFF;
  Width: 6em;
  height: 2em;
  justify-content: center;   
  text-align: center;
  margin: 0 auto;
  box-shadow: none;
  font-family: Teko;
  border-color: #C3232B;
  border-radius: 15px;
}

#Grid_formulaire{

  display: contents;
}

.form__container {
  background-color: #1C1F2B;
  justify-content: center; 
  width: 100%;
  padding-bottom: 4%;
}
form {
  background: white;
  text-align: left;
  /* padding: 10px 40px; */
  border-radius: 10px;
  justify-content: center; 
}
.formDisplay {
  display: flex;
  justify-content: center; 
} 
.formInput {
  width: 500px;
  padding: 2%;

}
.formMessage {
  width: 650px;
  padding: 2%;
  margin-top: 2%;
}

.form__container h3 {
    font-weight: bold;
    font-size: 2.5rem;
    margin-bottom: 2rem;
}

.submit {
  text-align: center !important;
 

}

.error {
  display: block;
  width: 100%;
  color: #ff0062;
  font-size: 1em;
  font-weight: bold;
}

.error:last-of-type {
  margin-bottom: 8px;
}

.MuiFormLabel-root{
  
  margin-top: -0.4rem !important;
}

.MuiFormControl-root{
  border: 1pw solid black !important;
}

  /* mobile */


  @media screen and (max-width:960px) {

    .formulaireTitle{

      padding-top: 2em !important;
      padding-bottom: 1em !important;
      font-size: 7vw !important;
     
}

.contact-coord h2{
  
  font-size: 5vw !important;
  padding-top: 40px;
  padding-bottom: 10px;
}

.contact-coord{
  
  padding-bottom: 3em;
  margin-bottom: 3em;
  
}

  }
.franchise {
    background-color: #1C1F2B;
    width: 100%;
    height: 100%;
    padding-top: 3em;
    padding-bottom: 3.8em;
   
}
.rond_red_header {
  margin: 0 auto;
}
.rond_red_header img{

  margin: 0 auto;
  width: 50%;
}

.icon_info_header{
  
  margin-top: 1.3em !important;
  fill: white  !important;
  width: 60px !important;
  height: 60px !important;
}

.franchiseTitle {
    padding-top: 180px !important;
    color: white;
    font-family: "Teko" !important;
    text-align: center;
    font-size: 75px !important;
    font-weight: 900 !important;
    padding-top: 30px;
    line-height: 132px;
    text-transform: uppercase;
    letter-spacing: 5% !important;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    text-underline-offset: 10px !important;
  }

  .franchiseAvantagesContainer {
    margin-top: 50px !important;
    margin-bottom: 80px !important;
    width: 30%;
    margin: 0 auto;
  }
  .franchiseAvantages {
    color: white;
    font-family: "Teko" !important;
    text-align: center;
    padding: 20px;
    font-size: 50px !important;
    font-weight: 800 !important;
    text-transform: uppercase;
    line-height: 82px;
    letter-spacing: 15% !important;
    justify-content: center;
    align-items: center;
    background-color: #C3232B;
  }
  .franchiseDescriptionContainer {
      display: flex;
      justify-content: center;
      padding-bottom: 90px;
  }
  .itemContainer {
      width: 18%;
      text-align: center;
      justify-content: center;
  }
  .itemDescription {
      color: white;
      padding-top: 15% !important;
  }

  @media(max-width: 960px) {
    .rond_red_header img{

      margin: 0 auto;
      width: 100%;
    }
    .franchiseDescriptionContainer {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .itemContainer {
        width: 50% !important;
        text-align: center;
        padding-bottom: 5%;
        justify-content: center;
    }
    .franchiseAvantages {
        font-size: 2em !important;
    }
    .franchiseAvantagesContainer {
        width: 80%;
      }
  }


  @media (max-width: 1199px) {
    .franchiseTitle {
      font-size:  3.3rem !important;
    }
}

  @media (max-width: 575px) {
    .franchiseTitle {
      font-size: 2.3rem !important;
      padding-bottom: 2%;
    }
  }

  .franchiseTitle {
    padding-top: 150px !important;
    
  }

  /* responsive PC*/

  #header_franchise{

    height: 100vh;
    

  }

  @media screen and (max-height:800px) {

    .franchise {
     
      padding-top: 0em;
      
     
  }

    .franchiseTitle {
      
      font-size: 10vh !important;
      
  }

  .franchiseAvantages {
    padding: 1vh !important;
    font-size: 7vh !important;
    
}

.franchiseAvantagesContainer {
  margin-top: 2vh !important;
  margin-bottom: 7vh !important;
  
}
    
  }

/* mobile*/
  @media screen and (max-width:960px) {

    .franchise {
      
      padding-bottom: 0em;
      padding-top: 0em;
     
  }

    .franchiseTitle {
      padding-bottom: 0%;
      font-size: 7vw !important;
      padding-top: 140px !important;
      
  }

  .franchiseAvantages {
    padding: 1vw;
    font-size: 6vw !important;
    
    
}

.franchiseAvantagesContainer {
  margin-bottom: 7vw !important;
  
}
.rond_red_header {
  
  width: 90px;
  height: 90px;
  
}

.icon_info_header{
  
  margin-top: 0.8em !important;
  width: 50px !important;
  height: 50px !important;


}

.itemDescription h5 {


  font-size: 3vw;
}

.franchiseAvantagesContainer {
  margin-top: 40px !important;
  
}

#header_franchise{

  height: -webkit-max-content;

  height: max-content;
  

}
  }

  @media screen and (max-width:600px) {



.itemDescription h5 {


      font-size: 4vw;
}

.rond_red_header {
  
  width: 60px;
  height: 60px;
  
}

.icon_info_header{
  
  margin-top: 0.6em !important;
  width: 30px !important;
  height: 30px !important;


}
  }
.informations{

  padding-top: 1em;
  padding-bottom: 3.8em;

}

.infosTitle {
    color: rgb(7, 7, 7);
    font-family: "Teko" !important;
    text-align: center;
    font-size: 75px !important;
    font-weight: 900 !important;
    padding-top: 30px;
    line-height: 132px;
    text-transform: uppercase;
    letter-spacing: 5% !important;
  }

  .cardContainerTop {
      margin-top: -30px !important;
      display: flex;
      justify-content: center;
      align-items: center;
  }
  .cardContainerBottom {
    display: flex;
    justify-content: center;
    align-items: center;

}
  .infosItemContainer {
    
    width: 300px;
    height: 250px;
    border-radius: 20px;
    text-align: center;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);    
    margin: 2%;
    padding: 3em;
    
  }
  .infosItemDescription {
      padding: 5%;
  }
  .infosItemDescription h5 {
    font-weight: bold;
}

.rond_red_info {
  margin: 0 auto;
}

.rond_red_info img{
  margin: 0 auto;
 width: 50%;
}

.icon_info{
  margin-top: 0.6em !important;
  fill: white  !important;
  width: 50px !important;
  height: 50px !important;
  

}


  /* responsive PC*/

  #informations_franchise{

    height: 100vh;

  }

  @media screen and (max-height:800px) {

    #informations_franchise{

      height: -webkit-max-content;

      height: max-content;
  
    }

    .infosTitle {
      font-size: 10vh !important;
    }
  

  }


  /* mobile */


  @media screen and (max-width:960px) {
    .rond_red_info img{
      margin: 0 auto;
     width: 100%;
    }
    #informations_franchise{

      height: -webkit-max-content;

      height: max-content;
  
    }

    .infosTitle {
      font-size: 7vw !important;
    }
  .infosItemContainer {
      width: 350px;
      height: 300px;
      margin: 5%;
      padding: 3.5em;
    }


    .rond_red_info{
      
      width: 90px;
      height: 90px;
      
    
    }
    
    .icon_info{
      margin-top: 0.8em !important;
      width: 50px !important;
      height: 50px !important;
      
    }

    .infosItemDescription {
      padding: 10%;
  }
    .infosItemDescription h5 {
      font-size: 1.7em;
  }

  .cardContainerTop {
      flex-direction: column;
      margin-top: 0px !important;

  }
  .cardContainerBottom {
      flex-direction: column;
}

  }

  @media screen and (max-width:600px) {

    #informations_franchise{

      height: -webkit-max-content;

      height: max-content;
  
    }

    .infosTitle {
      font-size: 7vw !important;
    }
  .infosItemContainer {
      width: 200px;
      height: 200px;
      margin: 5%;
      padding: 6%;
    }


    .rond_red_info{
      
      width: 60px;
      height: 60px;
      
    
    }
    
    .icon_info{
      margin-top: 0.6em !important;
      width: 30px !important;
      height: 30px !important;
      
    }

    .infosItemDescription {
      padding: 10%;
  }
    .infosItemDescription h5 {
      font-size: 1em;
  }

  .cardContainerTop {
      flex-direction: column;
      margin-top: 0px !important;

  }
  .cardContainerBottom {
      flex-direction: column;
}

  }
@font-face {
    font-family: 'Friday13v12';
    src: url(/static/media/Friday13v12.62480775.ttf) format('woff2');
  }

.Grid_Container{
    height: 100%;
    width: 100%;
    background: url(/static/media/cdn_fond.dd823646.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    padding-top: 15em;
    padding-bottom: 15em;
}
.commanderHeader {
    text-decoration: none;
    color: black;
}
.commanderHeader:hover {
    color: #FFFFFF;
}

.Div_Text_Header{
    height: 40%;
    /* font-size: 20em; */
    font-family: 'Friday13v12', Arial, sans-serif;
    text-align: center;
    color: #FFFFFF;
    margin: 0 auto;
    margin-top: -32vh;
}

.Div_Text_Header h1 {
    font-size: 1.8em;
}

.Div_Text_Header h2 {
    font-size: 1em;
    font-family: 'Teko';
}

#Btn_Commander_Accueil{
    font-size: var(--font-size-button);
    color: black;
    font-family: Teko;
    border-radius: 20px;
    border: white;
    padding: 8px 30px;
    margin-left: 2%;
}

#Btn_Commander_Accueil a{
    text-decoration: none;
    color: black;
}


#Btn_Commander_Accueil:hover {
    background-color: #db0b15;


}

@-webkit-keyframes glowing {
    0% { background-color: #f5f5f5; box-shadow: 0 0 5px #f5f5f5; }
    50% { background-color: #f8f8ff; box-shadow: 0 0 20px #f8f8ff; }
    100% { background-color: #fff; box-shadow: 0 0 5px #fff; }
    }

@keyframes glowing {
    0% { background-color: #f5f5f5; box-shadow: 0 0 5px #f5f5f5; }
    50% { background-color: #f8f8ff; box-shadow: 0 0 20px #f8f8ff; }
    100% { background-color: #fff; box-shadow: 0 0 5px #fff; }
    }
    #Btn_Commander_Accueil {
    -webkit-animation: glowing 1300ms infinite;
            animation: glowing 1300ms infinite;
    }


/* responsive */
#header {
    height: 100vh;
    position: relative;
    z-index: 5;
}

/* for w < 960 or h < 730 */
@media screen and (max-width: 960px) , (max-height: 800px) {

    #Btn_Commander_Accueil{
        font-size: var(--font-size-button-mobile);
        margin-top: 5vh;
        padding: 8px 20px;  
    }
}

/* -------------- WIDTH --------------------- */
/* w <= 1104 */
@media screen and (max-width: 1104px) {
    .Div_Text_Header {
       font-size: 4em;
       margin-top: -10vh;
    }
}
/* 746 <= w <= 1104 */
@media screen and (max-width: 746px) {
    .Div_Text_Header {
       font-size: 4em;
       margin-top: -10vh;
    }
}

@media screen and (max-width: 608px) {
    .Div_Text_Header {
       font-size: 2.5em;
       margin-top: -2vh;
    }
}

@media screen and (max-width: 514px) {
    .Div_Text_Header {
       font-size: 2.5em;
       margin-top: -15vh;
       margin-bottom: 10vh;
    }
}
@media screen and (max-width: 400px) {
    .Div_Text_Header {
       font-size: 2em;
       margin-top: -15vh;
       margin-bottom: 10vh;
    }
}

/* -------------- MIN WIDTH 332 --------------------- */


/* -------------- HEIGHT -------------- */

@media only screen and (max-width: 514px) and (max-height: 540px) {
    .Div_Text_Header {
        font-size: 2em;
        margin-top: -20vh;
    }
}

@media only screen and (min-width: 514px) and (max-width: 595px) and (max-height: 596px) {
    .Div_Text_Header {
        font-size: 3em;
        margin-top: -20vh;
    }
}

@media only screen and (min-width: 514px) and (max-width: 595px) and (max-height: 515px) {
    .Div_Text_Header {
        font-size: 2em;
        margin-top: -20vh;
    }
}

@media only screen and (min-width: 595px) and (max-width: 1104px) and (max-height: 790px) {
    .Div_Text_Header {
        font-size: 4em;
        margin-top: -16vh;
    }
}

@media only screen and (min-width: 595px) and (max-width: 1104px) and (max-height: 645px) {
    .Div_Text_Header {
        font-size: 3em;
        margin-top: -16vh;
    }
}

@media only screen and (min-width: 595px) and (max-width: 1104px) and (max-height: 540px) {
    .Div_Text_Header {
        font-size: 2em;
        margin-top: -16vh;
    }
}

@media only screen and (min-width: 1104px) and (max-height: 893px) {
    .Div_Text_Header {
        font-size: 6em;
        margin-top: -40vh;
    }
}

@media only screen and (min-width: 1104px) and (max-height: 741px) {
    .Div_Text_Header {
        font-size: 4em;
        margin-top: -20vh;
    }
}

@media only screen and (min-width: 1104px) and (max-height: 626px) {
    .Div_Text_Header {
        font-size: 3em;
        margin-top: -20vh;
    }
}

@media only screen and (min-width: 1104px) and (max-height: 529px) {
    .Div_Text_Header {
        font-size: 2em;
        margin-top: -16vh;
    }
}




/* -------------- MIN HEIGHT 550 -------------- */

/* -------------- WIDTH AND HEIGHT  --------------------- */

/* -------------- MIN WIDTH AND HEIGT --------------------- */


@font-face {
  font-family: 'Friday13v12';
  src: url(/static/media/Friday13v12.62480775.ttf) format('woff2');
}

.plats {
  /* background-color: #3c3c3c; */
  width: 100%;
  height: 100%;
  /*background-image: url("../../images/bg.png");*/
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #0d0d0d;
  position: relative;
  justify-content: center;
  z-index: 1;
  font-family: 'Teko' !important;
}
.platsTitle {
  color: white;
  font-family: 'Friday13v12', Arial, sans-serif !important;
  text-align: center;
  font-size: var(--font-size-titre-grande) !important;
  font-weight: 700 !important;
  padding-top: 135px;
  line-height: 72px;
  letter-spacing: 15% !important;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  text-underline-offset: 15px !important;
}

.star {
  height: 50px;
}

.cardItem {
  width: 280px;
  height: 500px;
  text-align: center;
  border-radius: 22px !important;
  margin: 0 auto;
  margin-bottom: 77px;
}

.cardItem img {
  width: 100%;
  height: 280px;
}

.cardTitle {
  font-family: "Teko" !important;
  padding-top:  10px !important;
  text-align: center;
  font-weight: 900 !important;
  font-size: 26px !important;
  letter-spacing: 0.05em !important;
  text-transform: uppercase;
}
.cardPrice {
  font-family: "Teko" !important;

  color: #C3232B;
  font-weight: bold !important;
  font-size: 30px !important;
}

.cardText {
  font-family: "Raleway" !important;
  align-items: center;
  justify-content: center;
  font-size: 16px !important;
  padding: 2%;

}

.command_button{
  text-align: center;
}

.telecharger_btn {
  text-align: center;
  text-decoration: none !important;
  color: black !important;
  background-color: #f3cf40;
  border-radius: 15px;
  text-transform: uppercase;
  font-size: var(--font-size-button);
  font-weight: 700;
  display: inline-block;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  padding: 2px 60px;
}
.telecharger_btn:hover {
  background-color: #dbc12f;

}


@-webkit-keyframes glowing {
  0% { background-color: #f5f5f5; box-shadow: 0 0 5px #f5f5f5; }
  50% { background-color: #f8f8ff; box-shadow: 0 0 20px #f8f8ff; }
  100% { background-color: #fff; box-shadow: 0 0 5px #fff; }
  }


@keyframes glowing {
  0% { background-color: #f5f5f5; box-shadow: 0 0 5px #f5f5f5; }
  50% { background-color: #f8f8ff; box-shadow: 0 0 20px #f8f8ff; }
  100% { background-color: #fff; box-shadow: 0 0 5px #fff; }
  }
  .telecharger_btn {
  -webkit-animation: glowing 1300ms infinite;
          animation: glowing 1300ms infinite;
  }

/* --------------- responsive ----------------- */
#plats {
  height: 100vh;
  position: relative;
  z-index: 4;
  margin-top: -98px;
}

.plats-card {
  display: flex;
  margin: 0 auto;
  width: 90%;
  padding-top: 2vh;
}

.slider-card {
  padding-top: 3vh;
  margin-bottom: 20px;
}

/* for w < 960 or h < 730 */
@media  screen and (max-width: 960px) , (max-height: 800px) {
  .platsTitle{
    font-size: var(--font-size-titre-moyenne) !important;
  }

  .telecharger_btn{
    font-size: var(--font-size-button-mobile);
    padding: 8px 20px;
  }

  .cardTitle {
    font-size: 22px !important;
  }
  .cardText {
    font-size: 14px !important;
  }
  .cardPrice{
    font-size: 24px !important;
  }
}

/* for w < 960 or h < 730 */
@media  screen and (max-width: 612px) , (max-height: 600px) {
  .platsTitle{
    font-size: calc(var(--font-size-titre-petite)/1) !important;
  }

  .telecharger_btn{
    font-size: var(--font-size-button-mobile);
    padding: 8px 20px;
  }

  .cardTitle {
    font-size: 22px !important;
  }
  .cardText {
    font-size: 14px !important;
  }
  .cardPrice{
    font-size: 24px !important;
  }
}

@media  screen and (max-width: 428px){
  .platsTitle{
    font-size: 30px !important;
  }
}

/* ------------- WIDTH ----------------*/

@media screen and (max-width: 1262px) {
  .cardItem{
    width: 270px;
  }
}

@media screen and (max-width: 959px) {
  .cardItem{
    width: 270px;
  }

  .command_button {
    padding-bottom: 30px;
  }

  #plats {
    height: -webkit-max-content;
    height: max-content;
  }
}

@media screen and (max-width: 450px) {
  .cardItem{
    width: 66vw;
  }
}

/* --------- MIN WIDTH 343 ------------- */

/* ---------- HEIGHT with width >= ------------- */
@media screen and (min-width: 960px) and (max-height: 960px) {
  .cardItem img {
    width: 100%;
    height: 23vh;
  }

  .cardItem {
    height: -webkit-max-content;
    height: max-content;
  }
}

@media screen and (min-width: 960px) and (max-height: 870px) {
  .cardItem img {
    width: 100%;
    height: 20vh;
  }
}

@media screen and (min-width: 960px) and (max-height: 738px) {
  .command_button {
    margin-top: -4vh;
  }

}

@media screen and (min-width: 960px) and (max-height: 677px) {
  .command_button {
    margin-top: -6vh;
  }

  .cardItem img {
    width: 100%;
    height: 15vh;
  }
}

@media screen and (min-width: 960px) and (max-height: 630px) {
  .command_button {
    margin-top: -7vh;
  }

  .cardItem img {
    width: 100%;
    height: 12vh;
  }
}

@media screen and (min-width: 960px) and (max-height: 578px) {
  .cardTitle {
    padding-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .cardText {
    padding: 0px !important;
  }
  .cardPrice {
    margin-bottom: -18px !important;
  }
  .command_button {
    margin-top: -10vh;
  }
  .platsTitle{
    padding-top: 116px;
  }
  .star {
    display: none;
  }
}

/* --------- MIN HEIGHT 477 ------------- */

/* --------- MOBILE VERSION ------------- */
@media only screen and (max-width: 960px) and (max-height: 677px) {
  .command_button{
    padding-top: 20px;
  }
}

@media screen and (max-width: 960px) {
  .cardItem img {
    height: 230px;
  }

  .cardItem  {
    height: -webkit-max-content;
    height: max-content;
  }
}
/* --------- MOBILE VERSION - MIN HEIGHT 493 ------------- */
@font-face {
  font-family: 'Friday13v12';
  src: url(/static/media/Friday13v12.62480775.ttf) format('woff2');
}

.galeriePage {
  /* background-color: #3c3c3c; */
  width: 100%;
  /*background-image: url("../../images/bg.png");*/
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /* background-color: #464646 !important; */
  position: relative;
  z-index: 1;
  font-family: 'Teko' !important;
}


.galerieTitle {
  color: white;
  font-family: 'Friday13v12', Arial, sans-serif !important;
  text-align: center;
  font-size: 42px !important;
  font-weight: 700 !important;
  padding-top: 100px;
  line-height: 72px;
  letter-spacing: 15% !important;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  text-underline-offset: 15px !important;
}

.star {
  height: 50px;
}
.galerieLine {
  justify-content: center;
}

.galerieImg {
  padding: 1%;
  max-width: 98% !important;
  height: 24vw;
}
 /* imageGES */
.image3{
  width: 36%;
}

.image8{
  width: 36.5%;
}

.image9{
  width: 25%;
}

/* style for image gallerie */
.gallerie-image-down {
  padding-top: 18%;
}

/* style for mobile */
.gallerie-mobile-version{
  margin: 0 auto;
  padding: 20px;
}

.galerieImgMobile{
  padding: 0 10px 10% 10px;
  width: 100%;
    margin: 0 auto;
    display: block;
}

/* style for image gallerie mobile*/
.gallerie-image-down-mobile {
  padding-top: 28%;
}

/* Responsive */
#galerie {
  height: -webkit-max-content;
  height: max-content;
  position: relative;
  z-index: 2;
  margin-top: -102px;
}

.galerieContainer {
  padding-top: 20px;
  padding-bottom: 40px;
}

/* for w < 960 or h < 730
@media  screen and (max-width: 960px) , (max-height: 800px) {
  .galerieTitle {
    font-size: var(--font-size-titre-moyenne) !important;
  }

}


@media  screen and (max-width: 612px) , (max-height: 600px) {
  .galerieTitle {
    font-size: var(--font-size-titre-petite) !important;
  } */

.cards {
    padding: 1rem;
    background: #464646;
  }
  
  h1 {
    text-align: center;
  }
  
  .cards__container {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 1120px;
    width: 90%;
    margin: 0 auto;
  }
  
  .cards__wrapper {
    position: relative;
    margin: 50px 0 45px;
  }
  
  .cards__items {
    /* margin-bottom: 24px; */
    padding-left: 0;
  }
  
  .cards__item {
    display: flex;
    flex: 1 1;
    margin: 0 1rem;
    border-radius: 10px;
  }
  
  .cards__item__link {
    margin-top: 2%;
    display: flex;
    flex-flow: column;
    width: 100%;
    /* box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017)); */
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
            filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    /* border-radius: 10px; */
    overflow: hidden;
    text-decoration: none;
  }
  
  .cards__item__pic-wrap {
    position: relative;
    width: 100%;
    padding-top: 67%;
    overflow: hidden;
  }
  
  .fade-img {
    -webkit-animation-name: fade-img;
            animation-name: fade-img;
    -webkit-animation-duration: 2s;
            animation-duration: 2s;
  }
  
  /* .cards__item__pic-wrap::after {
    content: attr(data-category);
    position: absolute;
    bottom: 0;
    margin-left: 10px;
    padding: 6px 8px;
    max-width: calc((100%) - 60px);
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    background-color: #1f98f4;
    box-sizing: border-box;
  } */
  
  .cards__item__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
  }
  
  .cards__item__img:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .cards__item__info {
    padding: 20px 30px 30px;
  }
  
  .cards__item__text {
    color: #252e48;
    font-size: 18px;
    line-height: 24px;
  }

  
  /* ------Responsive------ */
  
  @media only screen and (min-width: 1200px) {
    .content__blog__container {
      width: 84%;
    }
  }
  
  @media only screen and (min-width: 1024px) {
    .cards__items {
      display: flex;
    }
  }
  
  @media only screen and (max-width: 1024px) {
    .cards__item {
      margin-bottom: 0.2rem;
    }
  }

  /* for w < 960 or h < 730 */
@media  screen and (max-width: 960px) , (max-height: 800px) {
  .galerieTitle {
    font-size: var(--font-size-titre-moyenne) !important;
  }

}

/* for w < 960 or h < 730 */
@media  screen and (max-width: 612px) , (max-height: 600px) {
  .galerieTitle {
    font-size: var(--font-size-titre-petite) !important;
  }
}
@font-face {
  font-family: "Friday13v12";
  src: url(/static/media/Friday13v12.62480775.ttf) format("woff2");
}

.livraison {
  /* background-color: #3c3c3c; */
  width: 100%;
  height: 100vh;
  background-image: url(/static/media/cdn_fond_menu.8f70633e.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #0d0d0d;
  color: white;
  font-size: 40px;
  position: relative;
  text-align: center;
  justify-content: center;
  z-index: 1;
  font-family: "Teko" !important;
}

.livraison_container {
  display: inline;
  /* padding-top: 20px; */
  padding-bottom: 40px;
}

.livraison li {
  list-style-type: none;
  font-size: 24px;
}

.livraisonTitle {
  color: white;
  font-family: "Friday13v12", Arial, sans-serif !important;
  text-align: center;
  font-size: 42px !important;
  font-weight: 700 !important;
  padding-top: 100px;
  line-height: 72px;
  letter-spacing: 15% !important;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  text-underline-offset: 15px !important;
}

.livraisonTitle span {
    margin: 0 1% 0 1%;
}

.star {
  height: 50px;
}

@media  screen and (max-width: 960px) , (max-height: 800px) {
  .livraisonTitle {
    font-size: var(--font-size-titre-moyenne) !important;
  }

}
@media  screen and (max-width: 550px) , (max-height: 800px) {
  .livraisonTitle {
    font-size: 35px !important;
  }
  .star {
    height: 40px !important;
  }

}
@media  screen and (max-width: 400px) , (max-height: 800px) {
  .livraisonTitle {
    font-size: 30px !important;
  }
  .star {
    height: 35px !important;
  }
  .livraison ul {
    padding-left: 0;
  }

}


@font-face {
  font-family: 'Friday13v12';
  src: url(/static/media/Friday13v12.62480775.ttf) format('woff2');
}

.contact {
  /* background-color: #3c3c3c; */
  width: 100%;
  height: 100vh;
  /*background-image: url("../../images/bg.png");*/
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #0d0d0d;
  color:white;
  font-size: 40px;
  position: relative;
  text-align: center;
  justify-content: center;
  z-index: 1;
  font-family: 'Teko' !important;
}


.contact_container {
    display: inline;
    /* padding-top: 20px; */
    padding-bottom: 60px;
    font-family: 'Friday13v12';
}

.contact_elements {
  display: flex;
  flex-direction: column;
  padding: 0;
  
}

.contact ul {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 20px;
}
.contact li {
    list-style-type: none;
    font-size: 30px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.contact li p {
    font-family: 'Teko';
    font-size: 24px;
    background: #464646;
    width: 300px;
    border-radius: 15px;
    padding-top: 20px;
    
}

.contactTitle {
  color: white;
  font-family: 'Friday13v12', Arial, sans-serif !important;
  text-align: center;
  font-size: 42px !important;
  font-weight: 700 !important;
  padding-top: 50px;
  line-height: 72px;
  letter-spacing: 15% !important;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  text-underline-offset: 15px !important;
}

.contactTitle span {
    margin: 0 1.5% 0 1.5%;
}

.star {
  height: 50px;
}

#Btn_number {
  font-size: var(--font-size-button);
  color: black;
  font-family: Teko;
  border-radius: 20px;
  border: white;
  padding: 8px 30px;
  
  justify-content: center;
}

@-webkit-keyframes glowing {
  0% { background-color: #f5f5f5; box-shadow: 0 0 5px #f5f5f5; }
  50% { background-color: #f8f8ff; box-shadow: 0 0 20px #f8f8ff; }
  100% { background-color: #fff; box-shadow: 0 0 5px #fff; }
  }

@keyframes glowing {
  0% { background-color: #f5f5f5; box-shadow: 0 0 5px #f5f5f5; }
  50% { background-color: #f8f8ff; box-shadow: 0 0 20px #f8f8ff; }
  100% { background-color: #fff; box-shadow: 0 0 5px #fff; }
  }
  #Btn_number {
  -webkit-animation: glowing 1300ms infinite;
          animation: glowing 1300ms infinite;
  }

  .contact_elements li p {
    padding: 10px;
  }
.logo {
    height: 90px;
}

.footer-copyright{

    color: #FFFFFF;
    margin: 1em;
    font-family: Raleway;
    
}

#footer{
    padding: 50px 30px;
    font-family: Raleway;
    background: #0d0d0d;
}

/* Style for text in Footer */
#footer h5 {
    font-weight: bolder;
    color: white;
}

.horaires {
    color: white;
}

/* Style for image logo in Footer */

.footer-logo-border {
    border-right: solid 1px black;
}


/* Text go to left in desktop version */
.footer-text {
    padding-left: 20px;
}


/* Mobile version */
@media screen and (max-width: 960px) {
    /* Style for Footer */
    #footer {
        text-align: center;
    }

    #footer img {
        
        margin-bottom: 20px;
    }

    /* Border on the right for footer logo */
    .footer-logo-border {
        border-right: solid 0px var(--secondary-color-dark);
    }

    /* Text reset position */
    .footer-text {
        padding-left: 0px;
    }

    /* Copyright go to center in mobile version */
    .footer-copyright {
         text-align: center;
        margin: 2em;
        width: 100%;
    }
}

/* style for link footer */
.footer-link {
    color: white;
    text-decoration: none;
}

/* When mouse on it */
.footer-link:hover {
    font-weight: bolder;
    color: white;
}
.adminPage {
  position: relative;
}

.adminPage__container {
  display: flex;
}

.admin__container {
  width: 90%;
  margin: 2rem auto 2rem;
}

@media (max-width: 1250px) {
  .admin__container {
    width: 100%;
    margin: 10px;
  }
}

@media (max-width: 1000px) {
  .admin__container {
    padding-top: 1rem;
  }
}
@media (max-width: 676px) {
  .admin__container {
    padding-top: 2.5rem;
    padding-right: 10px;
  }
}
@media (max-width: 676px) {
  .admin__container h1 {
    font-size: 25px;
  }
  html { font-size: 80.5%; }
}

.adminNav {
  /* display: flex;
  flex-direction: column; */
  padding: 10px;
  background-color: #04295d;
  height: 108vh;
  position: relative;
  transition: all 0.3s;
  position: sticky;
  top: 0;
  left: 0;
  width: 20%;
}

.adminNav.active {
  width: 7%;
}

.adminNav__header {
  padding: 1rem;
  display: flex;
  justify-content: left;
  color: white;
}

.adminNav__header img {
  width: 120px;
  object-fit: contain;
  margin-right: 0.5em;
}
.adminNav__header p {
  width: 50px;
  object-fit: contain;
  margin-top: 1em;
}

.adminNav.active .adminNav__header img {
  width: 70px;
  margin-right: 2em;
}

.adminNav__title {
  text-align: center;
  color: white;
}

.adminNav.active .adminNav__title {
}

.adminNav__links {
  display: flex;
  flex-direction: column;
  padding: 0 1rem 1rem 1rem;
  height: 65vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.adminNav__link:hover {
  background-color: #61e0e6;
  color: white;
}

.adminNav.active .adminNav__links {
  overflow: hidden;
  height: -webkit-max-content;
  height: max-content;
}

.adminNav__links::-webkit-scrollbar {
  width: 5px; /* width of the entire scrollbar */
}

.adminNav__links::-webkit-scrollbar-track {
  background: rgb(65, 63, 63); /* color of the tracking area */
}

.adminNav__links::-webkit-scrollbar-thumb {
  background-color: #61e0e6; /* color of the scroll thumb */
  /* border-radius: 20px; roundness of the scroll thumb */
  /* border: 3px solid rgb(0, 0, 0); creates padding around scroll thumb */
}

.adminNav__link {
  margin: 10px 0;
  border: none;
  border-radius: 2px;
  padding: 3px;
  display: flex;
  align-items: center;
  cursor: pointer !important;
  background-color: white; /* #25bbe8 => aprés click */
  position: relative;
}

.adminNav__link-deconnexion {
  margin: 10px 0;
  border: none;
  border-radius: 2px;
  padding: 5px;
  display: flex;
  align-items: center;
  cursor: pointer !important;
  background-color: red;
  position: relative;
}
.adminNav__link-deconnexion:hover {
  color: white;
}

.adminNav__nouvelles_commandes__indicator {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 5px;
  background-color: rgb(17, 17, 17);
  color: rgb(233, 233, 233);
  padding: 0 5px;
  font-size: 14px;
  border-radius: 50%;
}

.adminNav__link p {
  margin: 0;
  opacity: 1;
  text-align: left;
}

.adminNav__link-deconnexion p {
  margin: 0;
  opacity: 1;
  text-align: left;
}

.adminNav.active .adminNav__link p {
  display: none;
}

.adminNav.active .adminNav__link-deconnexion p {
  display: none;
}

.adminNav__link__icone {
  margin: 0 15px 0 10px;
}

.adminNav.active .adminNav__link__icone {
  margin: auto;
}

.adminNav__link.active {
  background-color: #61e0e6;
}

.adminNav__close {
  position: absolute;
  bottom: 10px;
  right: 10px;
}


.adminNav.active .adminNav__close {
  width: 100%;
  display: flex;
  justify-content: center;
  right: 0;
}

.adminNav__close-btn {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.adminNav__close i {
  color: rgb(143, 140, 140);
}
.adminNav__close i:hover {
  color: #fff;
}

.adminNav.active .switch__container {
}

.adminNav__humburger {
  display: none;
}
@media (max-width: 1502px) {
  .adminNav {
    width: 23%;
  }
}
@media (max-width: 1305px) {
  .adminNav {
    width: 25%;
  }
}

@media (max-width: 1200px) {
  .adminNav.active {
    width: 9%;
  }

  .adminNav {
    width: 28%;
  }
}
@media (max-width: 1084px) {
  .adminNav {
    width: 30%;
  }
}
@media (max-width: 1001px) {
  .adminNav {
    width: 33%;
  }
}
@media (max-width: 912px) {
  .adminNav {
    width: 35%;
  }
}
@media (max-width: 854px) {
  .adminNav.active {
    width: 13%;
  }
  .adminNav {
    width: 40%;
  }
}
@media (max-width: 750px) {
  .adminNav {
    width: 45%;
  }

  .adminNav.active {
    width: 16%;
  }
}
@media (max-width: 676px) {
  .adminNav {
    position: fixed;
    top: 0;
    left: auto;
    right: -500px;
    width: 55%;
    z-index: 99;
    overflow: auto;
  }
  .adminNav::-webkit-scrollbar {
    width: 10px; /* width of the entire scrollbar */
  }

  .adminNav::-webkit-scrollbar-track {
    background: rgb(65, 63, 63); /* color of the tracking area */
  }

  .adminNav::-webkit-scrollbar-thumb {
    background-color: rgb(146, 145, 145); /* color of the scroll thumb */
    /* border-radius: 20px; roundness of the scroll thumb */
    /* border: 3px solid rgb(0, 0, 0); creates padding around scroll thumb */
  }

  .adminNav.active {
    width: 50%;
    right: 0;
  }

  .adminNav__close {
    display: none;
  }

  .adminNav.active .adminNav__close {
    display: none;
  }

  .adminNav__humburger {
    display: block;
    position: fixed;
    top: 10px;
    right: 15px;
    z-index: 99;
  }
  .adminNav.active .adminNav__humburger i {
    color: #fff;
  }

  .adminNav.active .adminNav__link p {
    display: block;
  }

  .adminNav.active .adminNav__link__icone {
    margin: 0 10px;
  }
}

@media (max-width: 676px) {
  .adminNav.active {
    width: 58%;
  }
}
@media (max-width: 500px) {
  .adminNav.active {
    width: 100%;
  }

  .adminNav.active .adminNav__nouvelles_commandes__indicator {
    font-size: 14px;
    background-color: black;
    color: rgb(233, 233, 233);
    right: 10px;
    top: 50%;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
  }
}

.switch__container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 10px;
}

.switch__container-indication {
  color: #fff;
  margin-bottom: 0.5rem;
}

.switch {
  border-radius: 30px;
  width: 70px;
  height: 35px;
  background-color: #fff;
  display: flex;
  padding: 3px 5px;
  border: 1px solid rgb(54, 54, 54);
}
.switch-round {
  width: 50%;
  border-radius: 50%;
  background-color: #ec412a;
}

.switch.active {
  justify-content: flex-end;
}
.switch.active .switch-round {
  background-color: #4cbc4a;
}

.commande__product-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.commande__product-container > div {
  display: flex;
  align-items: center;
}

.commande__produit-test {
  display: flex;
  flex-direction: row;
  margin-left: 50px;
}

.space {
  margin-bottom: 20px;
}

.commande__product-content {
  display: flex;
  justify-content: space-between;
}

.commande__product h2 {
  font-size: 1.3rem;
  margin: 0;
}
.commande__product-nom {
  margin: 0 0 0 1.5rem !important;
}

.commande__prix {
  font-size: 1.4rem;
}

.commande__product-quantite {
  color: rgb(53, 49, 49);
}

.commande__menu-container > div {
  display: flex;
}

.commande__menu-header {
  flex-direction: row;
  justify-content: space-between;
}

.commande__menu-test {
  display: flex;
  flex-direction: row;
}

.commande__prix {
  font-size: 1.4rem;
}

.commande__menu-quantite {
  color: rgb(53, 49, 49);
  padding-right: 28px;
}

.commande__menu {
  flex-direction: column;
  margin-left: 50px;
  margin-bottom: 20px;
}

.commande__menu-val {
  margin-left: 10px;
  margin-bottom: 0px;
}

.commande__commentaire {
  max-width: 400px;
}

.commande__totale {
  margin: 10px 0;
  width: 100%;
}
.commande__totale__sous-totale,
.commande__totale-ttc {
  width: 25%;
  display: flex;
  justify-content: space-between;
  margin-left: auto;
  padding-top: 5px;
  border-top: 1px solid black;
}
.commande__totale-ttc {
  padding-top: 0;
}
.commande__totale-ttc p {
  font-size: 1.3rem;
}
.commande__totale-ttc p span {
  font-size: 0.9rem;
}
.commande__totale-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.commande__totale-mode__container {
  display: flex;
  align-items: center;
}
.commande__totale-mode {
  margin: 0 1rem 0 0;
  font-size: 1rem;
  color: #ec412a;
  font-weight: 700;
  padding: 5px;
}

.commande__ref {
  margin: 0;
  font-size: 15px;
  padding: 5px;
}
.commande__totale-ttc {
  border: none;
  font-weight: bold;
  font-size: 1.2rem;
}
.commande__totale-ttc p {
  margin: 0;
}

.commande__client p {
  margin-bottom: 5px;
}

.commande__client__nom {
  font-size: 1.5rem;
  font-weight: 700;
}

.commande__client__adresse,
.commande__client__telephone {
  font-size: 1.2rem;
}

.commande__client__telephone {
  text-decoration: underline;
}

.commande__valider_btn {
  margin: auto !important;
  background-color: #ec412a !important;
  padding: 10px 15px;
  color: rgb(255, 255, 255);
  border: none;
  border-radius: 5px;
  transition: background 0.3s ease;
}

.commande__valider_btn:hover {
  background-color: #e7331b !important;
}

.commande__valider_btn.terminer {
  background-color: #4cbc4a !important;
}
.commande__valider_btn.terminer:hover {
  background-color: #37b935 !important;
}
.product__container {
  margin: auto;
  display: flex;
  flex-direction: column;
  max-width: 650px;
  width: 100% !important;
}

.bouton__container {
  /* margin: 0 auto; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  /* width: 350px; */
}

@media (max-width: 1004px) {
  .commande__totale__sous-totale,
  .commande__totale-ttc {
    width: 30%;
    display: flex;
    justify-content: space-between;
  }
}
@media (max-width: 906px) {
  .commande__totale-ttc p {
    font-size: 1.2rem;
  }

  .commande__totale__sous-totale p {
    font-size: 0.9rem;
    margin-bottom: 5px;
  }
}
@media (max-width: 861px) {
  .commande__prix {
    font-size: 1.1rem;
  }

  /* .commande__totale-mode {
    font-size: 1.4rem;
  } */

  .commande__totale-ttc p {
    font-size: 1.1rem;
  }
  .commande__totale-ttc p span {
    font-size: 0.7rem;
  }

  .commande__totale__sous-totale p {
    font-size: 0.8rem;
    margin-bottom: 5px;
  }

  .commande__product h2 {
    font-size: 1.1rem;
    margin: 0;
  }
  .commande__client__nom {
    font-size: 1.3rem;
    font-weight: 700;
  }

  .commande__client__adresse,
  .commande__client__telephone {
    font-size: 1rem;
  }
}
@media (max-width: 796px) {
  /* .commande__totale-mode {
    font-size: 1.1rem;
  } */

  .commande__totale__sous-totale,
  .commande__totale-ttc {
    width: 40%;
  }
}

.bold-details {
  font-weight: bold;
  line-height: 0px;
  color:#04295D;
}
.spacing{
  padding: 1%;
}

.title-details {
  font-weight: bold;
  font-size: 1.5rem;
  color:#04295D;
}


.ecarter-prix {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.suivi_activite__jour {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 2rem;
  border-bottom: 2px solid rgb(129, 128, 128);
}

.suivi_activite__jour__CA,
.suivi_activite__jour__commandes {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 1rem;
}
.suivi_activite__jour__CA-heading span {
  font-size: 1rem;
}
.suivi_activite__jour__CA p,
.suivi_activite__jour__commandes p {
  font-size: 1.5rem;
  margin-bottom: 5px;
  color: #4cbc4a;
  font-weight: 700;
}

.suivi_activite__jour__CA__card,
.suivi_activite__jour__commandes__card {
  margin-top: 1rem;
  padding: 2rem;
  border: 1px solid #4cbc4a;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}
.suivi_activite__jour__CA__card h1,
.suivi_activite__jour__commandes__card h1 {
  font-size: 3rem;
}

.suivi_activite__jour_exacte {
  margin-top: 2rem;
}

.suivi_activite__jour_exacte-calendrier {
  margin: auto;
  max-width: 300px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.suivi_activite__jour_exacte-calendrier label {
  margin: 0;
}
.suivi_activite__jour_exacte .suivi_activite__jour {
  border: none;
}

.suivi_activite__jour_exacte
  .suivi_activite__jour
  .suivi_activite__jour__CA__card,
.suivi_activite__jour_exacte
  .suivi_activite__jour
  .suivi_activite__jour__commandes__card {
  border: 2px solid #c4c4c4;
}

.suivi_activite__jour_exacte .suivi_activite__jour .suivi_activite__jour__CA p,
.suivi_activite__jour_exacte
  .suivi_activite__jour
  .suivi_activite__jour__commandes
  p {
  color: black !important;
}

.suivi_activite__stripe {
  border-top: 2px solid rgb(129, 128, 128);
  padding-top: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.suivi_activite__stripe h1 {
  font-size: 1.2rem;
  text-align: center;
  margin-bottom: 10px;
}

.suivi_activite__stripe .link {
  padding: 5px 15px;
  font-size: 1.3rem;
  border: none;
  background-color: #635bff;
  border-radius: 2px;
}

@media (max-width: 550px) {
  .suivi_activite__jour {
    flex-direction: column;
  }

  .suivi_activite__jour__commandes {
    margin-top: 2rem;
  }
  .suivi_activite__jour__CA__card h1,
.suivi_activite__jour__commandes__card h1 {
  font-size: 2rem;
}

}

#menu-page {
    display: contents;
}

.product-item{
    width: 70%;
}

.menu-board-img{
    width: 70%;
    height: 150px;
    margin: 20px auto;
}
.myModal__backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(57, 57, 57, 0.15);
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.myModal__modal {max-height: 100vh;
  overflow-y: auto;
  max-width: 500px;
  width: 95%;
  padding: 1rem;
  background: white;
  border-radius: 10px;
  position: relative;
  /*border: 7px solid #285AB1;*/
}

.myModal__modal__close {
  position: absolute;
  top: 2px;
  right: 10px;
}
.myModal__modal__close i {
  font-size: 20px;
  pointer-events: none;
}

.myModal__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  margin-bottom: 10px;
  border-bottom: 1px solid lightgray;
}

.myModal__header h1 {
  text-align: center;
  /*color: rgb(100, 100, 100);*/
  font-size: 2.2rem;

}
.myModal__header h2 {
  text-align: center;
  /*color: rgb(100, 100, 100);*/
  font-size: 1.5rem;
}
.myModal__header p {
  margin: 0;
  /*color:#285AB1;*/
  font-size: 1.6rem;

}

.myModal__header img {
  max-width: 200px;
  max-height: 150px;
  object-fit: contain;
}

.myModal__body,
.myModal__footer {
  padding: 10px 0;
  /*font-family: 'Bevan';*/
  font-size: 1rem;
  /*font-weight: bold;*/
}

.myModal__body__heading {
  font-size: 1.3rem;
  /*color: #D22B31;*/
  /*font-family: 'Bevan';*/
  /*font-weight: bold;*/
}

.myModal__footer {
  border: none;
  display: flex;
  justify-content: center;
}

#ajouter_produit {
    padding: 19px;
    margin-top: -7px;
    margin-left: 10px;
    color: white;
    border-radius: 10px;
    background-color: #04295d;  
}

#ajouter_btn {
    padding: 10px 15px;
    color: white;
    margin-top: 0px;
    background-color: #04295d;
}

#modifier_btn {
    padding: 10px 20px;
    color: white;
    margin-top: 0px;
    background-color: #04295d;
}



#supprimer_btn{
    padding: 10px;
    color: white;
    margin-top: 0px;
    background-color: red;
}

/*@media (max-width: 600px) {
  .MuiButton-label {
    padding: 1rem;
    font-size: 1rem;
   
  }
  .MuiButton-label .MuiButton-endIcon{
    display: none !important;
  }
}*/

.basic-input{
  padding-bottom: 15px ;

} 

/*@media (max-width: 500px) {
  .myModal__header img {
    max-width: 175px;
    max-height: 125px;
  }

  .myModal__header h1 {
    font-size: 1.8rem;
  }
  .myModal__header h2 {
    font-size: 1.3rem;
  }
  .myModal__modal {
    margin-top: 50px;
  }
}*/

.loadingImg{
  position: absolute;
  display: block;
  background-color: #04295d;
  border-radius: 50%;
  height: 20%;
  top: 50%;
  left: 50%;
  right: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 1;
}

.commander__ {
  margin-top: 80px !important;
  /* background: url("../images/etoile.png") no-repeat center fixed; */
  background-size: cover;
}

.commander__container {
  padding: 6.5rem 0rem;
  position: relative;
  display: flex;
  flex-direction: row;
}

#demo-simple-select {
  min-width: 100px;
  margin-top: 1%;
}
.commander__container__cards {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row !important;
  justify-content: center;
}

.commander__container__cards fieldset {
  width: 100%;
}

.dishes-menu__radio-container {
  display: flex;
  flex-direction: row !important;
  flex-wrap: wrap;
  justify-content: center;
}

.menu__radio-container {
  flex-direction: row !important;
}

.commander__carte {
  display: none;
}

.radio-choice__menu {
  display: flex;
  justify-content: center;
  margin: 0 !important;
}

@media (max-width: 1080px) {
  .commander__container {
    grid-template-columns: 1fr;
    padding: 2.5rem 1rem !important;
  }

  .commander__carte {
    display: block;
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 100;
  }

  .commander__carte.white i {
    color: rgb(238, 236, 236) !important;
  }
}


.switch__container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 10px;
}

.switch__container-indication {
  color: #fff;
  margin-bottom: 0.5rem;
}

.switch {
  border-radius: 30px;
  width: 70px;
  height: 35px;
  background-color: #fff;
  display: flex;
  padding: 3px 5px;
  border: 1px solid rgb(54, 54, 54);
  margin: 0;
}
.switch-round {
  width: 50%;
  border-radius: 50%;
  background-color: #ec412a;
}

.switch.active {
  justify-content: flex-end;
}
.switch.active .switch-round {
  background-color: #4cbc4a;
}

#creation_menus{
    display: flex;
    margin-top: 5%;
    text-align: center;
    width: 100%;
    align-items: center;
    justify-content: center;
}

#creation_menus > *{
    display: inline-block;
}

.creation_menus_popup{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    text-align: center;
}

.creation_menus_popup .contain{
    background-color: white;
    margin: auto;
    margin-top: 5%;
    width: 80%;
    max-height: 80%;
    overflow-y: scroll;
}

.creation_menus_popup .close{
    position: absolute;
    color: white;
    top: 2%;
    right: 5%;
    font-size: 2em;
}

.creation_menus_popup_button{
    margin: unset;
}

.creation_menus_popup_input{
    width: 10%;
    margin-right: 1%;
    margin-bottom: 0.1%;
}

.creation_menus_popup ul {
    list-style-type: none;
    margin-top: 1%;
}

.creation_menus_popup select{
    border: 1px solid #E8EAED;
    border-radius: 5px;
}

.creation_menus_popup button.button_inside {
    background-color: #61e0e6;
    padding: 0.5% 0 0.5% 0;
    color: white;
    width: 10%;
    border: none;
    margin-bottom: 2%;
}

.creation_menus_popup .align{
    width: 100%;
    text-align: center;
}

.creation_menus_popup .align > * {
    max-width: 25%;
    width : 20%;
    display: inline-block;
}

.creation_menus_popup .line{
    position: relative;
    margin: auto;
    margin-top: 1%;
    margin-bottom: 1%;
    width: 50%;
    height: 2px;
    background-color: #61e0e6;
}



.alertsSupp{
    position: absolute;
    top:0;
    left: 0;
    min-height: 100%;
    width: 98.5vw;
    background-color: rgb(0,0,0,0.5);
    overflow: hidden;
    z-index: 200;
}

.alertsSupp .content{
    height: 35%;
    width: 35%;
    margin: auto;
    margin-top: 20%;
    background-color: white;
    border-radius: 10px;
    overflow: hidden;
}

.alertsSupp h4{
    text-align: center;
    margin-top: 2%;
}

.alertsSupp h5{
    text-align: center;
}

.centeredButtons{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5%;
    margin-bottom: 1%;
}
.yes_button {
    position: relative;
    border: 1px solid white;
    border-radius: 0.5em;
    background-color: #21bd36;
    font-weight: bold;
    color: white;
    width: 5em;
    height: 3em;
    padding: 0;
    margin: 0;
  }

.no_button {
    position: relative;
    margin: auto;
    border: 1px solid white;
    border-radius: 0.5em;
    background-color: #ec412a;
    font-weight: bold;
    color: white;
    width: 5em;
    height: 3em;
    padding: 0;
    margin: 0;
    margin-left: 1%;
  }
.commande__product-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.commande__product-container > div {
  display: flex;
  align-items: center;
}

.commande__product h2 {
  font-size: 1.3rem;
  margin: 0;
}
.commande__product-nom {
  margin: 0 0 0 1.5rem !important;
}

.commande__prix {
  font-size: 1.4rem;
}

.commande__product-quantite {
  color: rgb(53, 49, 49);
}
.commande__totale {
  margin: 10px 0;
  width: 100%;
}
.commande__totale__sous-totale,
.commande__totale-ttc {
  width: 25%;
  display: flex;
  justify-content: space-between;
  margin-left: auto;
  padding-top: 5px;
  border-top: 1px solid black;
}
.commande__totale-ttc {
  padding-top: 0;
}
.commande__totale-ttc p {
  font-size: 1.3rem;
}
.commande__totale-ttc p span {
  font-size: 0.9rem;
}
.commande__totale-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.commande__totale-mode__container {
  display: flex;
  align-items: center;
}
.commande__totale-mode {
  margin: 0 1rem 0 0;
  font-size: 1.7rem;
  color: #ec412a;
  font-weight: 700;
}

.commande__ref {
  margin: 0;
  font-size: 15px;
}
.commande__totale-ttc {
  border: none;
  font-weight: bold;
  font-size: 1.2rem;
}
.commande__totale-ttc p {
  margin: 0;
}

.commande__client p {
  margin-bottom: 5px;
}

.commande__client__nom {
  font-size: 1.5rem;
  font-weight: 700;
}

.commande__client__adresse,
.commande__client__telephone {
  font-size: 1.2rem;
}

.commande__client__telephone {
  text-decoration: underline;
}

.commande__valider_btn {
  margin: auto !important;
  background-color: #ec412a !important;
  padding: 10px 15px;
  color: rgb(255, 255, 255);
  border: none;
  border-radius: 5px;
  transition: background 0.3s ease;
}

.commande__valider_btn:hover {
  background-color: #e7331b !important;
}

.commande__valider_btn.terminer {
  background-color: #4cbc4a !important;
}
.commande__valider_btn.terminer:hover {
  background-color: #37b935 !important;
}
.product__container {
  margin: auto;
  display: flex;
  flex-direction: column;
  max-width: 650px;
  width: 100% !important;
}

@media (max-width: 1004px) {
  .commande__totale__sous-totale,
  .commande__totale-ttc {
    width: 30%;
    display: flex;
    justify-content: space-between;
  }
}
@media (max-width: 906px) {
  .commande__totale-ttc p {
    font-size: 1.2rem;
  }

  .commande__totale__sous-totale p {
    font-size: 0.9rem;
    margin-bottom: 5px;
  }
}
@media (max-width: 861px) {
  .commande__prix {
    font-size: 1.1rem;
  }

  .commande__totale-mode {
    font-size: 1.4rem;
  }

  .commande__totale-ttc p {
    font-size: 1.1rem;
  }
  .commande__totale-ttc p span {
    font-size: 0.7rem;
  }

  .commande__totale__sous-totale p {
    font-size: 0.8rem;
    margin-bottom: 5px;
  }

  .commande__product h2 {
    font-size: 1.1rem;
    margin: 0;
  }
  .commande__client__nom {
    font-size: 1.3rem;
    font-weight: 700;
  }

  .commande__client__adresse,
  .commande__client__telephone {
    font-size: 1rem;
  }
}
@media (max-width: 796px) {
  .commande__totale-mode {
    font-size: 1.1rem;
  }

  .commande__totale__sous-totale,
  .commande__totale-ttc {
    width: 40%;
  }
}



.commande__product-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.commande__product-container > div {
  display: flex;
  align-items: center;
}

.commande__product h2 {
  font-size: 1.3rem;
  margin: 0;
}
.commande__product-nom {
  margin: 0 0 0 1.5rem !important;
}

.commande__prix {
  font-size: 1.4rem;
}

.commande__product-quantite {
  color: rgb(53, 49, 49);
}
.commande__totale {
  margin: 10px 0;
  width: 100%;
}
.commande__totale__sous-totale,
.commande__totale-ttc {
  width: 25%;
  display: flex;
  justify-content: space-between;
  margin-left: auto;
  padding-top: 5px;
  border-top: 1px solid black;
}
.commande__totale-ttc {
  padding-top: 0;
}
.commande__totale-ttc p {
  font-size: 1.3rem;
}
.commande__totale-ttc p span {
  font-size: 0.9rem;
}
.commande__totale-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.commande__totale-mode__container {
  display: flex;
  align-items: center;
}
.commande__totale-mode {
  margin: 0 1rem 0 0;
  font-size: 1.7rem;
  color: #ec412a;
  font-weight: 700;
}

.commande__ref {
  margin: 0;
  font-size: 15px;
}
.commande__totale-ttc {
  border: none;
  font-weight: bold;
  font-size: 1.2rem;
}
.commande__totale-ttc p {
  margin: 0;
}

.commande__client p {
  margin-bottom: 5px;
}

.commande__client__nom {
  font-size: 1.5rem;
  font-weight: 700;
}

.commande__client__adresse,
.commande__client__telephone {
  font-size: 1.2rem;
}

.commande__client__telephone {
  text-decoration: underline;
}

.commande__valider_btn {
  margin: auto !important;
  background-color: #ec412a !important;
  padding: 10px 15px;
  color: rgb(255, 255, 255);
  border: none;
  border-radius: 5px;
  transition: background 0.3s ease;
}

.commande__valider_btn:hover {
  background-color: #e7331b !important;
}

.commande__valider_btn.terminer {
  background-color: #4cbc4a !important;
}
.commande__valider_btn.terminer:hover {
  background-color: #37b935 !important;
}
.product__container {
  margin: auto;
  display: flex;
  flex-direction: column;
  max-width: 650px;
  width: 100% !important;
}

@media (max-width: 1004px) {
  .commande__totale__sous-totale,
  .commande__totale-ttc {
    width: 30%;
    display: flex;
    justify-content: space-between;
  }
}
@media (max-width: 906px) {
  .commande__totale-ttc p {
    font-size: 1.2rem;
  }

  .commande__totale__sous-totale p {
    font-size: 0.9rem;
    margin-bottom: 5px;
  }
}
@media (max-width: 861px) {
  .commande__prix {
    font-size: 1.1rem;
  }

  .commande__totale-mode {
    font-size: 1.4rem;
  }

  .commande__totale-ttc p {
    font-size: 1.1rem;
  }
  .commande__totale-ttc p span {
    font-size: 0.7rem;
  }

  .commande__totale__sous-totale p {
    font-size: 0.8rem;
    margin-bottom: 5px;
  }

  .commande__product h2 {
    font-size: 1.1rem;
    margin: 0;
  }
  .commande__client__nom {
    font-size: 1.3rem;
    font-weight: 700;
  }

  .commande__client__adresse,
  .commande__client__telephone {
    font-size: 1rem;
  }
}
@media (max-width: 796px) {
  .commande__totale-mode {
    font-size: 1.1rem;
  }

  .commande__totale__sous-totale,
  .commande__totale-ttc {
    width: 40%;
  }
}

.commande__product-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.commande__product-container > div {
  display: flex;
  align-items: center;
}

.commande__product h2 {
  font-size: 1.3rem;
  margin: 0;
}
.commande__product-nom {
  margin: 0 0 0 1.5rem !important;
}

.commande__prix {
  font-size: 1.4rem;
}

.commande__product-quantite {
  color: rgb(53, 49, 49);
}
.commande__totale {
  margin: 10px 0;
  width: 100%;
}
.commande__totale__sous-totale,
.commande__totale-ttc {
  width: 25%;
  display: flex;
  justify-content: space-between;
  margin-left: auto;
  padding-top: 5px;
  border-top: 1px solid black;
}
.commande__totale-ttc {
  padding-top: 0;
}
.commande__totale-ttc p {
  font-size: 1.3rem;
}
.commande__totale-ttc p span {
  font-size: 0.9rem;
}
.commande__totale-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.commande__totale-mode__container {
  display: flex;
  align-items: center;
}
.commande__totale-mode {
  margin: 0 1rem 0 0;
  font-size: 1.7rem;
  color: #ec412a;
  font-weight: 700;
}

.commande__ref {
  margin: 0;
  font-size: 15px;
}
.commande__totale-ttc {
  border: none;
  font-weight: bold;
  font-size: 1.2rem;
}
.commande__totale-ttc p {
  margin: 0;
}

.commande__client p {
  margin-bottom: 5px;
}

.commande__client__nom {
  font-size: 1.5rem;
  font-weight: 700;
}

.commande__client__adresse,
.commande__client__telephone {
  font-size: 1.2rem;
}

.commande__client__telephone {
  text-decoration: underline;
}

.commande__valider_btn {
  margin: auto !important;
  background-color: #ec412a !important;
  padding: 10px 15px;
  color: rgb(255, 255, 255);
  border: none;
  border-radius: 5px;
  transition: background 0.3s ease;
}

.commande__valider_btn:hover {
  background-color: #e7331b !important;
}

.commande__valider_btn.terminer {
  background-color: #4cbc4a !important;
}
.commande__valider_btn.terminer:hover {
  background-color: #37b935 !important;
}
.product__container {
  margin: auto;
  display: flex;
  flex-direction: column;
  max-width: 650px;
  width: 100% !important;
}

@media (max-width: 1004px) {
  .commande__totale__sous-totale,
  .commande__totale-ttc {
    width: 30%;
    display: flex;
    justify-content: space-between;
  }
}
@media (max-width: 906px) {
  .commande__totale-ttc p {
    font-size: 1.2rem;
  }

  .commande__totale__sous-totale p {
    font-size: 0.9rem;
    margin-bottom: 5px;
  }
}
@media (max-width: 861px) {
  .commande__prix {
    font-size: 1.1rem;
  }

  .commande__totale-mode {
    font-size: 1.4rem;
  }

  .commande__totale-ttc p {
    font-size: 1.1rem;
  }
  .commande__totale-ttc p span {
    font-size: 0.7rem;
  }

  .commande__totale__sous-totale p {
    font-size: 0.8rem;
    margin-bottom: 5px;
  }

  .commande__product h2 {
    font-size: 1.1rem;
    margin: 0;
  }
  .commande__client__nom {
    font-size: 1.3rem;
    font-weight: 700;
  }

  .commande__client__adresse,
  .commande__client__telephone {
    font-size: 1rem;
  }
}
@media (max-width: 796px) {
  .commande__totale-mode {
    font-size: 1.1rem;
  }

  .commande__totale__sous-totale,
  .commande__totale-ttc {
    width: 40%;
  }
}

.compte-header h1 {
  text-align: center;
  font-size: 2.5rem;
  padding-bottom: 1em;
  border-bottom: 1px solid gray;
  font-weight: bold;
  color: #04295d;
}

.compte {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin: 6em;
  margin-top: 5em;
}

.button {
  padding: 1.5em;
  margin: 1em;
  font-weight: bold;
  border-radius: 1em;
  background-color: #04295d;
  color: white;
  width: 220px;
  height: 170px;
}

.button:hover {
  background-color: #2fbfff;
}

@media screen and (max-width: 800px) {
  .compte {
    display: grid;
    flex-wrap: wrap;
    flex-direction: column;
    margin-top: 1em;
    margin: 0em;
  }
}
@media screen and (max-width: 1050px) {
  .compte {
    margin-top: 2em;
    margin: -2em;
    padding-top: 5em;
  }
}

.infos_header h1 {
  text-align: center;
  font-size: 2.5rem;
  padding-bottom: 1em;
  border-bottom: 1px solid gray;
  font-weight: bold;
  color: #04295d;
}

.infos_button {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin: 6em;
  margin-top: 5em;
}

.button {
  padding: 1.5em;
  margin: 1em;
  font-weight: bold;
  border-radius: 1em;
  background-color: #04295d;
  color: white;
  width: 220px;
  height: 170px;
}

.button:hover {
  background-color: #2fbfff;
}


.societe-header h1 {
    text-align: center;
    font-size: 2.5rem;
    padding-bottom: 1em;
    border-bottom: 1px solid gray;
    font-weight: bold;
    color: #04295D;
}


.societe_label {
    padding: 1em;
}
.societe_container {
    
    display: grid;
    justify-content: center;
    flex-direction: column;
    
}

.societe_input {
    padding: 1em;
    border: none;
    box-shadow: 5px 4px 11px rgb(0 0 255 / 20%);
    margin: 1em;
    font-weight: bold;
    
    /* border: solid 4px #3EBDE6; */
}

.registre_button{
    text-align: center;
    position: relative;
    border: 1px solid white;
    border-radius: 0.5em;
    background-color: #3EBDE6;
    color: #fff;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 1.2em;
    width: 11em;
    height: 3.2em;
    padding: 0; 
    margin: 1em auto;
    
}

.edit_button{
    text-align: center;
    position: relative;
    border: 1px solid white;
    border-radius: 0.5em;
    background-color: #D22B31;
    color: #fff;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 1.2em;
    width: 11em;
    height: 3.2em;
    padding: 0; 
    margin: 1em auto;
    
    
}

.societe_adresse {
    display: flex;
}

.societe_label {
    font-weight: bold;
}

.code_input {
    padding: 1em;
    border: none;
    box-shadow: 5px 4px 11px rgb(0 0 255 / 20%);
    margin: 1em;
    font-weight: bold;
    
    
}

.ville_input {
    padding: 1em;
    border: none;
    box-shadow: 5px 4px 11px rgb(0 0 255 / 20%);
    margin: 1em;
    font-weight: bold;
    
}

.adresse {
    display: flex;
    flex-wrap: wrap;
}

.societe_button {
    display: flex;
    flex-wrap: wrap;
}

.errors {
    color: black;

}



@media screen and (max-width: 750px){
    .societe_adresse {

    display: flex;
    flex-direction: column;
    }


}
.caisse-header h1 {
    text-align: center;
    font-size: 2.5rem;
    padding-bottom: 1em;
    border-bottom: 1px solid gray;
    font-weight: bold;
    color: #04295D;
}


.caisse_container {
    
    display: grid;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    
}


.caisse_div {
    padding: 1em;
}

.caisse_input {

    padding: 1em ;
    border: none;
    box-shadow: 5px 4px 11px rgb(0 0 255 / 20%);
    margin: 1em;
    font-weight: bold;
}



.caisse_title h2 {
    text-align: center;
    padding-bottom: 1em;
    color: #04295D;
}

.caisse_label {
    font-weight: bold;
}

.adresse_caisse {
    display: flex;
    flex-wrap: wrap;
}

.registre_caisse_button{
    text-align: center;
    position: relative;
    border: 1px solid white;
    border-radius: 0.5em;
    background-color: #3EBDE6;
    color: #fff;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 1.2em;
    width: 11em;
    height: 3.2em;
    padding: 0; 
    margin: 1em auto;
    
}

.errors {
    color: black;

}

.caisse_button {
    display: flex;
    flex-wrap: wrap;
}

@media screen and (max-width: 850px){
    .adresse_caisse {

    display: flex;
    flex-direction: column;
    }

}
.dirigeant-header h1 {
    text-align: center;
    font-size: 3.5rem;
    padding-bottom: 1em;
    border-bottom: 1px solid gray;
    font-weight: bold;
    color: #04295D;
}

.dirigeant_container {

    display: grid;
    justify-content: center;
    flex-direction: column;

}

.dirigeant_label {
    padding: 1em;
}

.dirigeant_input {
    box-shadow: 5px 4px 11px rgb(0 0 255 / 20%);
}


.edit_button_dirigeant{
    text-align: center;
    position: relative;
    border: 1px solid white;
    border-radius: 0.5em;
    background-color: #3EBDE6;
    color: #fff;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 1.2em;
    width: 11em;
    height: 3.2em;
    padding: 0; 
    margin: 1em auto;


}

.dirigeant_label {
    font-weight: bold;
}





.dirigeant_adresse {
    display: flex;
}


.dirigeant_button {
    display: flex;
    flex-wrap: wrap;
}


 .errors {
     color: black;

 }

@media screen and (max-width: 450px){
    .dirigeant_adresse {

    display: flex;
    flex-direction: column;
    }


}

@media screen and (max-width: 450px){
    .dirigeant_content{

    display: flex;
    flex-direction: column;
    }

}
.Qrcode-header h1 {
  text-align: center;
  font-size: 2.5rem;
  padding-bottom: 1em;
  border-bottom: 1px solid gray;
  font-weight: bold;
  color: #04295d;
}
.nom {
  box-sizing: content-box;
  width: 100%;
  text-align: center;
  font-size: 2.5rem;
  font-weight: bold;
  color: #04295d;
  margin-top: 1em;
  justify-content: center;
}

.button {
  padding: 1.5em;
  /* box-shadow: 1px 5px 6px 2px rgb(0 0 255 / 20%); */
  margin: 1em;
  font-weight: bold;
  border-radius: 1em;
  background-color: #04295d;
  color: white;
  width: 220px;
  height: 170px;
}

.button:hover {
  background-color: #2fbfff;
}

@media screen and (max-width: 800px) {
  .Gestioncommercial {
    display: grid;
    flex-wrap: wrap;
    flex-direction: column;
    margin-top: 1em;
    justify-content: center;
  }
}
@media screen and (max-width: 1050px) {
  .Gestioncommercial {
    padding-top: 2em;
    margin: -2em;
    justify-content: center;
  }
}

.reg_button {
  text-align: center;
  border: 1px solid white;
  border-radius: 0.5em;
  background-color: #3ebde6;
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 1.2em;
  width: 11em;
  height: 3.2em;
  padding: 0;

  margin: auto;
}
.QrcodeContent {
  margin: auto;
  display: flex;
  justify-content: center;
}
.societe_button {
  display: flex;
  justify-content: center;
}
@media (max-width: 500px) {
  html {
    font-size: 80%;
    padding-top: 10px;
  }
}
.Comptabilite-header h1 {
  text-align: center;
  font-size: 2.5rem;
  padding-bottom: 1em;
  border-bottom: 1px solid gray;
  font-weight: bold;
  color: #04295d;
}

.Comptabilite-button {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin: 5em;
  margin-top: 5em;
}

.button {
  padding: 1.5em;
  margin: 1em;
  font-weight: bold;
  border-radius: 1em;
  background-color: #04295d;
  color: white;
  width: 220px;
  height: 170px;
}

.button:hover {
  border: solid 4px #3ebde6;
}
@media screen and (max-width: 1050px) {
  .button {
    padding-top: 2em;
    margin: -2em;
  }
}

.Facture-header h1 {
  text-align: center;
  font-size: 3.5rem;
  padding-bottom: 1em;
  border-bottom: 1px solid gray;
  font-weight: bold;
  color: #04295d;
}

.button {
  padding: 1.5em;
  margin: 1em;
  font-weight: bold;
  border-radius: 1em;
  background-color: #04295d;
  color: white;
  width: 220px;
  height: 170px;
}

.Facture {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin: 6em;
  margin-top: 5em;
}

.facture_button:hover {
  background-color: #2fbfff;

  border: solid 4px #3ebde6;
}

.facture-header h1 {
  text-align: center;
  font-size: 2.5rem;
  padding-bottom: 1em;
  border-bottom: 1px solid gray;
  font-weight: bold;
  color: #04295d;
}

.facture {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.facture_center {
  display: flex;
  justify-content: center;
}

.facture_container {
  display: grid;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 5em;
}

.facture_div {
  padding: 1em;
}

.facture_input {
  border: none;
  margin: 1em;
  font-weight: bold;
  box-shadow: 5px 4px 11px rgb(0 0 255 / 20%);
}

.facture_label {
  font-weight: bold;
}

.adresse_facture {
  display: flex;
  flex-wrap: wrap;
}

.registre_facture_button {
  text-align: center;
  position: relative;
  border: 1px solid white;
  border-radius: 0.5em;
  background-color: #3ebde6;
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 1.2em;
  width: 11em;
  height: 3.2em;
  padding: 0;
  margin: 1em auto;
}

.errors {
  color: black;
}

.facture_button {
  display: flex;
  flex-wrap: wrap;
}

.button {
  color: black;
}

/* .telecharger_div {
    text-align: center;
    position: relative;
    border: 1px solid white;
    border-radius: 0.5em;
    background-color: #3EBDE6;
    color: #fff;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 1.2em;
    width: 11em;
    height: 3.2em;
    padding: 0; 
    margin: 1em auto;
} */

.facture_tva {
  border: none;
  margin: 1em;
  font-weight: bold;
  box-shadow: 5px 4px 11px rgb(0 0 255 / 20%);
  width: 6em;
}

.fas {
  cursor: pointer;
}

@media screen and (max-width: 850px) {
  .adresse_facture {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 850px) {
  .facture_tva {
    margin-left: 3em;
  }
}

.Gestiondesstocks-header h1 {
  text-align: center;
  font-size: 3.5rem;
  padding-bottom: 1em;
  border-bottom: 1px solid gray;
  font-weight: bold;
  color: #04295d;
}

.Gestiondesstocks {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin: 6em;
  margin-top: 5em;
}

.button {
  padding: 1.5em;
  /* box-shadow: 1px 5px 6px 2px rgb(0 0 255 / 20%); */
  margin: 1em;
  font-weight: bold;
  border-radius: 1em;
  background-color: #04295d;
  color: white;
  width: 220px;
  height: 170px;
}

.button:hover {
  background-color: #2fbfff;
}

@media screen and (max-width: 800px) {
  .Gestiondesstocks {
    display: grid;
    flex-wrap: wrap;
    flex-direction: column;
    margin-top: 1em;
    margin: 0em;
  }
}
@media screen and (max-width: 1050px) {
  .Gestiondesstocks {
    margin-top: 2em;
    margin: -2em;
    padding-top: 2em;
  }
}

.Gestiondesressourceshumaines-header h1 {
  text-align: center;
  font-size: 3.5rem;
  padding-bottom: 1em;
  border-bottom: 1px solid gray;
  font-weight: bold;
  color: #04295d;
}

.Gestiondesressourceshumaines {
  display: flex;
  flex-flow: row wrap;
  margin: 6em;
  margin-top: 5em;
  justify-content: center;
}

.rh_button {
  padding: 1.5em;
  /* box-shadow: 1px 5px 6px 2px rgb(0 0 255 / 20%); */
  margin: 1em;
  font-weight: bold;
  border-radius: 1em;
  background-color: #04295d;
  color: white;
  width: 220px;
  height: 170px;
}

.rh_button:hover {
  background-color: #2fbfff;
}

@media screen and (max-width: 800px) {
  .Gestiondesressourceshumaines {
    display: grid;
    flex-wrap: wrap;
    flex-direction: column;
    margin-top: 1em;
    margin: 0em;
  }
}
@media screen and (max-width: 1050px) {
  .Gestiondesressourceshumaines {
    padding-top: 2em;
    margin: -2em;
  }
}

.facture_button:hover {
  background-color: #2fbfff;

  border: solid 4px #3ebde6;
}

.Gestioncommerciale-header h1 {
  text-align: center;
  font-size: 3.5rem;
  padding-bottom: 1em;
  border-bottom: 1px solid gray;
  font-weight: bold;
  color: #04295d;
}

.Gestioncommerciale {
  display: flex;
  flex-flow: row wrap;
  margin: 6em;
  margin-top: 5em;
  justify-content: center;
}

.button {
  padding: 1.5em;
  /* box-shadow: 1px 5px 6px 2px rgb(0 0 255 / 20%); */
  margin: 1em;
  font-weight: bold;
  border-radius: 1em;
  background-color: #04295d;
  color: white;
  width: 220px;
  height: 170px;
}

.button:hover {
  background-color: #2fbfff;
}

@media screen and (max-width: 800px) {
  .Gestioncommerciale {
    display: grid;
    flex-wrap: wrap;
    flex-direction: column;
    margin-top: 1em;
  }
}
@media screen and (max-width: 1050px) {
  .Gestioncommerciale {
    padding-top: 2em;
    margin: -2em;
    justify-content: center;
  }
}
.registr_button{
  text-align: center;
  border: 1px solid white;
  border-radius: 0.5em;
  background-color: #3EBDE6;
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 1.2em;
  width: 11em;
  height: 3.2em;
  padding: 0; 
  margin-right: -40em;
  margin-top: 2em;
  
}
.Contratdetravail-header h1 {
    text-align: center;
    font-size: 3.5rem;
    padding-bottom: 1em;
    border-bottom: 1px solid gray;
    font-weight: bold;
    color: #04295d;
  }
  
  .Contratdetravail_button {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin: 6em;
    margin-top: 5em;
  }
  
  .button {
    padding: 1.5em;
    margin: 1em;
    font-weight: bold;
    border-radius: 1em;
    background-color: #04295d;
    color: white;
    width: 220px;
    height: 170px;
  }
  
  .button:hover {
    background-color: #2fbfff;
  }
  
.societe-header h1 {
    text-align: center;
    font-size: 2.5rem;
    padding-bottom: 1em;
    border-bottom: 1px solid gray;
    font-weight: bold;
    color: #04295D;
}


.societe_label {
    padding: 1em;
}
.societe_container {
    
    display: grid;
    justify-content: center;
    flex-direction: column;
    
}

.societe_input {
    padding: 1em;
    border: none;
    box-shadow: 5px 4px 11px rgb(0 0 255 / 20%);
    margin: 1em;
    font-weight: bold;
    
    /* border: solid 4px #3EBDE6; */
}

.registr_button{
    text-align: center;
    border: 1px solid white;
    border-radius: 0.5em;
    background-color: #3EBDE6;
    color: #fff;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 1.2em;
    width: 11em;
    height: 3.2em;
    padding: 0; 
    margin: 1em auto;
    
}

.edit_button{
    text-align: center;
    position: relative;
    border: 1px solid white;
    border-radius: 0.5em;
    background-color: #D22B31;
    color: #fff;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 1.2em;
    width: 11em;
    height: 3.2em;
    padding: 0; 
    margin: 1em auto;
    
    
}

.societe_adresse {
    display: flex;
}

.societe_label {
    font-weight: bold;
}

.code_input {
    padding: 1em;
    border: none;
    box-shadow: 5px 4px 11px rgb(0 0 255 / 20%);
    margin: 1em;
    font-weight: bold;
    
    
}

.ville_input {
    padding: 1em;
    border: none;
    box-shadow: 5px 4px 11px rgb(0 0 255 / 20%);
    margin: 1em;
    font-weight: bold;
    
}

.pays_input {
    padding: 1em;
    border: none;
    box-shadow: 5px 4px 11px rgb(0 0 255 / 20%);
    margin: 1em;
    font-weight: bold;
    
}

.adresse {
    display: flex;
    flex-wrap: wrap;
}

.societe_button {
    display: flex;
    flex-wrap: wrap;
}

.errors {
    color: black;

}

.sexe_input {
    padding: 1em;
    border: none;
    box-shadow: 1px 4px 11px rgb(0 0 255 / 20%);
    margin: 1em;
    font-weight: bold;
    padding: 18.5px 20px;
    
}


.numsejour_input {
    display: flex;
    flex-wrap: wrap;
}



.numdetitre_employe {
    padding: 1em;
    border: none;
    box-shadow: 9px 3px 21px rgb(0 0 255 / 80%);
    margin: 8em;
    font-weight: bold;
    
}




.MuiOutlinedInput-input {
    padding: 18.5px 20px;
}




.prenomemploye_input {
    padding: 1em;
    border: none;
    box-shadow: 1px 4px 11px rgb(0 0 255 / 20%);
    margin: 1em;
    font-weight: bold;
    
}



@media screen and (max-width: 750px){
    .societe_adresse {

    display: flex;
    flex-direction: column;
    }


}
.dirigeant-header h1 {
  text-align: center;
  font-size: 2.5rem;
  padding-bottom: 1em;
  /*border-bottom: 1px solid gray;*/
  font-weight: bold;
  color: #04295d;
}

.facture__fournisseur {
  display: flex;
  flex-wrap: wrap;
}

.fournisseur_fact {
  text-align: center;
  position: relative;
  font-size: 2.5em;
}

.facture__montantHT {
  display: flex;
  flex-wrap: wrap;
}

.montantHT_fact {
  text-align: center;
  position: relative;
  font-size: 1.8em;
}

.facture__montantTVA {
  display: flex;
  flex-wrap: wrap;
}

.montantTVA_fact {
  text-align: center;
  position: relative;
  font-size: 1.8em;
  color: black;
}

.facture__date {
  display: flex;
  flex-wrap: wrap;
}

.date_fact {
  text-align: center;
  position: relative;
  font-size: 1.2em;
  font-weight: bold;
}

.facture__photo {
  display: flex;
  flex-wrap: wrap;
}

.facture_button {
  display: flex;
  flex-wrap: wrap;
}

.photo_fact {
  border: 1px solid black;
  text-align: center;
  position: relative;
  padding: 0;
  margin: 1em auto;
  width: 100%;
  max-width: 800px;
  height: auto;
}
.facture__button {
  display: flex;
  justify-content: space-between;
}
.registre__button {
  text-align: center;
  position: relative;
  border: 1px solid white;
  border-radius: 0.5em;
  background-color: #3ebde6;
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 1.2em;
  width: 11em;
  height: 3.2em;
  padding: 0;
  margin: 1em auto;
}

.supp_button {
  text-align: center;
  position: relative;
  border: 1px solid white;
  border-radius: 0.5em;
  background-color: #ec412a;
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 1.2em;
  width: 11em;
  height: 3.2em;
  padding: 0;
  margin: 1em auto;
}

.facture__container {
  display: flex;
  flex-direction: column;

  align-items: center;
}

.suivi_activite__jour_exacte-calendrier {
  margin: auto;
  max-width: 300px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.suivi_activite__jour_exacte-calendrier label {
  margin: 0;
}

.reset_button {
  margin-left: 28rem;
  text-align: center;
  position: relative;
  border: 1px solid white;
  border-radius: 0.5em;
  background-color: #3ebde6;
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 1.2em;
  width: 11em;
  height: 3.2em;
  padding: 0;
  margin: 1em auto;
}

.res_button {
  display: flex;
  flex-wrap: wrap;
}
.main {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
}
.MuiAccordionDetails-root {
  display: flex;
  padding: 8px 16px 16px;
  justify-content: center;
}
admin__container{
  margin-right: 10px;
  padding-right: 10px;
}
.button_valider{
    margin-top: 2%;
    background-color: #61e0e6;
    border: none;
    width: 10%;
    font-size: 1.2em;
}
/* ::placeholder {
  color: black;
  opacity: 1;
}

:-ms-input-placeholder {
  color: black;
}

::-ms-input-placeholder {
  color: black;
} */

.click2FA{
  color: #61e0e6;
  cursor: pointer;
}

label.text{
  margin: 0;
  display: block;
  height: 5%;
}

span.sub_title{
  text-align: left;
}
.alerts {
  position: fixed;
  top: 110px;
  right: 20px;
  z-index: 99;
  /* height: 80vh;
  padding: 1rem;
  overflow-y: auto;
  overflow-x: hidden;
  pointer-events: none; */
}

.alert {
  width: 350px;
  padding: 1rem;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fafafa;
  border-radius: 10px;
  box-shadow: 2px 4px 9px 0px #656161;
  position: relative;
  overflow: hidden;
}

.alert > img {
  max-width: 30px;
  /* height: auto; */
  max-height: 30px;
  object-fit: contain;
  padding: 0.8rem;
}

.alert > p {
  margin: 0;
}

.alert > p > span {
  color:red;
  font-family: 'Roboto';
  font-weight: bold;
}

.alert > .close {
  position: absolute;
  top: 7px;
  right: 10px;
  cursor: pointer;
  pointer-events: all;
  font-size: 15px;
}

.alert .progress {
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 100%;
  background-color: #fafafa;
}

.alert .progress-bar {
  background-color: #dadada;
}

@media (max-width: 550px) {
  .alerts {
    right: 10px;
  }

  .alert {
    width: 300px;
  }
}

/* when height < 730 and width < 960 => change size to mobile */

:root {
    --font-size: 18px;
    --font-size-mobile: 15px;
    --font-size-titre-grande: 70px;
    --font-size-titre-moyenne: 55px;
    --font-size-titre-petite: 40px;
    --font-size-button: 40px;
    --font-size-button-mobile: 30px;
}
