@import url("https://fonts.googleapis.com/css2?family=Teko:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300&display=swap");
@font-face {
  font-family: "Friday13v12";
  src: url("../../images/Friday13v12.ttf") format("woff2");
}

.livraison {
  /* background-color: #3c3c3c; */
  width: 100%;
  height: 100vh;
  background-image: url("../../images/crocsdelanight/cdn_fond_menu.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #0d0d0d;
  color: white;
  font-size: 40px;
  position: relative;
  text-align: center;
  justify-content: center;
  z-index: 1;
  font-family: "Teko" !important;
}

.livraison_container {
  display: inline;
  /* padding-top: 20px; */
  padding-bottom: 40px;
}

.livraison li {
  list-style-type: none;
  font-size: 24px;
}

.livraisonTitle {
  color: white;
  font-family: "Friday13v12", Arial, sans-serif !important;
  text-align: center;
  font-size: 42px !important;
  font-weight: 700 !important;
  padding-top: 100px;
  line-height: 72px;
  letter-spacing: 15% !important;
  text-decoration-line: underline;
  text-underline-offset: 15px !important;
}

.livraisonTitle span {
    margin: 0 1% 0 1%;
}

.star {
  height: 50px;
}

@media  screen and (max-width: 960px) , (max-height: 800px) {
  .livraisonTitle {
    font-size: var(--font-size-titre-moyenne) !important;
  }

}
@media  screen and (max-width: 550px) , (max-height: 800px) {
  .livraisonTitle {
    font-size: 35px !important;
  }
  .star {
    height: 40px !important;
  }

}
@media  screen and (max-width: 400px) , (max-height: 800px) {
  .livraisonTitle {
    font-size: 30px !important;
  }
  .star {
    height: 35px !important;
  }
  .livraison ul {
    padding-left: 0;
  }

}

