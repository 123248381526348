@import url('https://fonts.googleapis.com/css2?family=Teko:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300&display=swap');
@font-face {
  font-family: 'Friday13v12';
  src: url('../../images/Friday13v12.ttf') format('woff2');
}

.contact {
  /* background-color: #3c3c3c; */
  width: 100%;
  height: 100vh;
  /*background-image: url("../../images/bg.png");*/
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #0d0d0d;
  color:white;
  font-size: 40px;
  position: relative;
  text-align: center;
  justify-content: center;
  z-index: 1;
  font-family: 'Teko' !important;
}


.contact_container {
    display: inline;
    /* padding-top: 20px; */
    padding-bottom: 60px;
    font-family: 'Friday13v12';
}

.contact_elements {
  display: flex;
  flex-direction: column;
  padding: 0;
  
}

.contact ul {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 20px;
}
.contact li {
    list-style-type: none;
    font-size: 30px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.contact li p {
    font-family: 'Teko';
    font-size: 24px;
    background: #464646;
    width: 300px;
    border-radius: 15px;
    padding-top: 20px;
    
}

.contactTitle {
  color: white;
  font-family: 'Friday13v12', Arial, sans-serif !important;
  text-align: center;
  font-size: 42px !important;
  font-weight: 700 !important;
  padding-top: 50px;
  line-height: 72px;
  letter-spacing: 15% !important;
  text-decoration-line: underline;
  text-underline-offset: 15px !important;
}

.contactTitle span {
    margin: 0 1.5% 0 1.5%;
}

.star {
  height: 50px;
}

#Btn_number {
  font-size: var(--font-size-button);
  color: black;
  font-family: Teko;
  border-radius: 20px;
  border: white;
  padding: 8px 30px;
  
  justify-content: center;
}

@keyframes glowing {
  0% { background-color: #f5f5f5; box-shadow: 0 0 5px #f5f5f5; }
  50% { background-color: #f8f8ff; box-shadow: 0 0 20px #f8f8ff; }
  100% { background-color: #fff; box-shadow: 0 0 5px #fff; }
  }
  #Btn_number {
  animation: glowing 1300ms infinite;
  }

  .contact_elements li p {
    padding: 10px;
  }