@import url('https://fonts.googleapis.com/css2?family=Teko:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300&display=swap');
@font-face {
  font-family: 'Friday13v12';
  src: url('../../../images/Friday13v12.ttf') format('woff2');
}

.galeriePage {
  /* background-color: #3c3c3c; */
  width: 100%;
  /*background-image: url("../../images/bg.png");*/
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /* background-color: #464646 !important; */
  position: relative;
  z-index: 1;
  font-family: 'Teko' !important;
}


.galerieTitle {
  color: white;
  font-family: 'Friday13v12', Arial, sans-serif !important;
  text-align: center;
  font-size: 42px !important;
  font-weight: 700 !important;
  padding-top: 100px;
  line-height: 72px;
  letter-spacing: 15% !important;
  text-decoration-line: underline;
  text-underline-offset: 15px !important;
}

.star {
  height: 50px;
}
.galerieLine {
  justify-content: center;
}

.galerieImg {
  padding: 1%;
  max-width: 98% !important;
  height: 24vw;
}
 /* imageGES */
.image3{
  width: 36%;
}

.image8{
  width: 36.5%;
}

.image9{
  width: 25%;
}

/* style for image gallerie */
.gallerie-image-down {
  padding-top: 18%;
}

/* style for mobile */
.gallerie-mobile-version{
  margin: 0 auto;
  padding: 20px;
}

.galerieImgMobile{
  padding: 0 10px 10% 10px;
  width: 100%;
    margin: 0 auto;
    display: block;
}

/* style for image gallerie mobile*/
.gallerie-image-down-mobile {
  padding-top: 28%;
}

/* Responsive */
#galerie {
  height: max-content;
  position: relative;
  z-index: 2;
  margin-top: -102px;
}

.galerieContainer {
  padding-top: 20px;
  padding-bottom: 40px;
}

/* for w < 960 or h < 730
@media  screen and (max-width: 960px) , (max-height: 800px) {
  .galerieTitle {
    font-size: var(--font-size-titre-moyenne) !important;
  }

}


@media  screen and (max-width: 612px) , (max-height: 600px) {
  .galerieTitle {
    font-size: var(--font-size-titre-petite) !important;
  } */

.cards {
    padding: 1rem;
    background: #464646;
  }
  
  h1 {
    text-align: center;
  }
  
  .cards__container {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 1120px;
    width: 90%;
    margin: 0 auto;
  }
  
  .cards__wrapper {
    position: relative;
    margin: 50px 0 45px;
  }
  
  .cards__items {
    /* margin-bottom: 24px; */
    padding-left: 0;
  }
  
  .cards__item {
    display: flex;
    flex: 1;
    margin: 0 1rem;
    border-radius: 10px;
  }
  
  .cards__item__link {
    margin-top: 2%;
    display: flex;
    flex-flow: column;
    width: 100%;
    /* box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017)); */
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    /* border-radius: 10px; */
    overflow: hidden;
    text-decoration: none;
  }
  
  .cards__item__pic-wrap {
    position: relative;
    width: 100%;
    padding-top: 67%;
    overflow: hidden;
  }
  
  .fade-img {
    animation-name: fade-img;
    animation-duration: 2s;
  }
  
  /* .cards__item__pic-wrap::after {
    content: attr(data-category);
    position: absolute;
    bottom: 0;
    margin-left: 10px;
    padding: 6px 8px;
    max-width: calc((100%) - 60px);
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    background-color: #1f98f4;
    box-sizing: border-box;
  } */
  
  .cards__item__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
  }
  
  .cards__item__img:hover {
    transform: scale(1.1);
  }
  
  .cards__item__info {
    padding: 20px 30px 30px;
  }
  
  .cards__item__text {
    color: #252e48;
    font-size: 18px;
    line-height: 24px;
  }

  
  /* ------Responsive------ */
  
  @media only screen and (min-width: 1200px) {
    .content__blog__container {
      width: 84%;
    }
  }
  
  @media only screen and (min-width: 1024px) {
    .cards__items {
      display: flex;
    }
  }
  
  @media only screen and (max-width: 1024px) {
    .cards__item {
      margin-bottom: 0.2rem;
    }
  }

  /* for w < 960 or h < 730 */
@media  screen and (max-width: 960px) , (max-height: 800px) {
  .galerieTitle {
    font-size: var(--font-size-titre-moyenne) !important;
  }

}

/* for w < 960 or h < 730 */
@media  screen and (max-width: 612px) , (max-height: 600px) {
  .galerieTitle {
    font-size: var(--font-size-titre-petite) !important;
  }
}