@font-face {
    font-family: 'Friday13v12';
    src: url('../../../images/Friday13v12.ttf') format('woff2');
  }

.Grid_Container{
    height: 100%;
    width: 100%;
    background: url("../../../images/crocsdelanight/cdn_fond.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    padding-top: 15em;
    padding-bottom: 15em;
}
.commanderHeader {
    text-decoration: none;
    color: black;
}
.commanderHeader:hover {
    color: #FFFFFF;
}

.Div_Text_Header{
    height: 40%;
    /* font-size: 20em; */
    font-family: 'Friday13v12', Arial, sans-serif;
    text-align: center;
    color: #FFFFFF;
    margin: 0 auto;
    margin-top: -32vh;
}

.Div_Text_Header h1 {
    font-size: 1.8em;
}

.Div_Text_Header h2 {
    font-size: 1em;
    font-family: 'Teko';
}

#Btn_Commander_Accueil{
    font-size: var(--font-size-button);
    color: black;
    font-family: Teko;
    border-radius: 20px;
    border: white;
    padding: 8px 30px;
    margin-left: 2%;
}

#Btn_Commander_Accueil a{
    text-decoration: none;
    color: black;
}


#Btn_Commander_Accueil:hover {
    background-color: #db0b15;


}

@keyframes glowing {
    0% { background-color: #f5f5f5; box-shadow: 0 0 5px #f5f5f5; }
    50% { background-color: #f8f8ff; box-shadow: 0 0 20px #f8f8ff; }
    100% { background-color: #fff; box-shadow: 0 0 5px #fff; }
    }
    #Btn_Commander_Accueil {
    animation: glowing 1300ms infinite;
    }


/* responsive */
#header {
    height: 100vh;
    position: relative;
    z-index: 5;
}

/* for w < 960 or h < 730 */
@media screen and (max-width: 960px) , (max-height: 800px) {

    #Btn_Commander_Accueil{
        font-size: var(--font-size-button-mobile);
        margin-top: 5vh;
        padding: 8px 20px;  
    }
}

/* -------------- WIDTH --------------------- */
/* w <= 1104 */
@media screen and (max-width: 1104px) {
    .Div_Text_Header {
       font-size: 4em;
       margin-top: -10vh;
    }
}
/* 746 <= w <= 1104 */
@media screen and (max-width: 746px) {
    .Div_Text_Header {
       font-size: 4em;
       margin-top: -10vh;
    }
}

@media screen and (max-width: 608px) {
    .Div_Text_Header {
       font-size: 2.5em;
       margin-top: -2vh;
    }
}

@media screen and (max-width: 514px) {
    .Div_Text_Header {
       font-size: 2.5em;
       margin-top: -15vh;
       margin-bottom: 10vh;
    }
}
@media screen and (max-width: 400px) {
    .Div_Text_Header {
       font-size: 2em;
       margin-top: -15vh;
       margin-bottom: 10vh;
    }
}

/* -------------- MIN WIDTH 332 --------------------- */


/* -------------- HEIGHT -------------- */

@media only screen and (max-width: 514px) and (max-height: 540px) {
    .Div_Text_Header {
        font-size: 2em;
        margin-top: -20vh;
    }
}

@media only screen and (min-width: 514px) and (max-width: 595px) and (max-height: 596px) {
    .Div_Text_Header {
        font-size: 3em;
        margin-top: -20vh;
    }
}

@media only screen and (min-width: 514px) and (max-width: 595px) and (max-height: 515px) {
    .Div_Text_Header {
        font-size: 2em;
        margin-top: -20vh;
    }
}

@media only screen and (min-width: 595px) and (max-width: 1104px) and (max-height: 790px) {
    .Div_Text_Header {
        font-size: 4em;
        margin-top: -16vh;
    }
}

@media only screen and (min-width: 595px) and (max-width: 1104px) and (max-height: 645px) {
    .Div_Text_Header {
        font-size: 3em;
        margin-top: -16vh;
    }
}

@media only screen and (min-width: 595px) and (max-width: 1104px) and (max-height: 540px) {
    .Div_Text_Header {
        font-size: 2em;
        margin-top: -16vh;
    }
}

@media only screen and (min-width: 1104px) and (max-height: 893px) {
    .Div_Text_Header {
        font-size: 6em;
        margin-top: -40vh;
    }
}

@media only screen and (min-width: 1104px) and (max-height: 741px) {
    .Div_Text_Header {
        font-size: 4em;
        margin-top: -20vh;
    }
}

@media only screen and (min-width: 1104px) and (max-height: 626px) {
    .Div_Text_Header {
        font-size: 3em;
        margin-top: -20vh;
    }
}

@media only screen and (min-width: 1104px) and (max-height: 529px) {
    .Div_Text_Header {
        font-size: 2em;
        margin-top: -16vh;
    }
}




/* -------------- MIN HEIGHT 550 -------------- */

/* -------------- WIDTH AND HEIGHT  --------------------- */

/* -------------- MIN WIDTH AND HEIGT --------------------- */

